import {bookingTypes, membershipTypes} from "./constants";
import {store} from "../redux/store";
import {getDisabledPagesProps} from "./DisablePages";
import {sortAlphabetically} from "./functions";

export const getFiltersConfig = (filterTypes, selectedLocation, t) => {
    const box = store.getState().site.box
    let config = {}

    if(filterTypes?.vacancy) {
        config['vacancy'] = {
            options: [{label: t('show-all'), value: 'all'}, {label: t('only-available-sessions'), value: 'open'}],
            isMulti: false,
            filterFunc: (obj, selected) => selected === 'all' ? true : (obj.free > 0 && obj.booking_option !== bookingTypes.PAST),
            selected: 'all',
            defaultValue: 'all',
            isRadio: true,
            outerDisplay: false,
            ...(filterTypes.vacancy.rest ?? {})
        }
    }

    if(filterTypes?.courseVacancy) {
        config['courseVacancy'] = {
            options: [{label: t('course-vacancy-all'), value: 'all'}, {label: t('only-available-courses'), value: 'open'}],
            isMulti: false,
            filterFunc: (obj, selected) => selected === 'all' ? true : (!obj.isFull && !obj.isBookedUser),
            selected: 'all',
            defaultValue: 'all',
            isRadio: true,
            outerDisplay: false
        }
    }

    if(filterTypes?.trial) {
        config['trial'] = {
            options: [{label: t('trial-all-filter'), value: 'all'}, {label: t('only-trial-filter'), value: 'trial'}],
            isMulti: false,
            filterFunc: (obj, selected) => selected === 'all' ? true : (obj.booking_option !== bookingTypes.PAST && obj.free > 0 && obj.series?.membership_types.find(item => item.type === membershipTypes.TRIAL)),
            selected: 'all',
            defaultValue: 'all',
            isRadio: true
        }
    }

    if(filterTypes?.coaches && filterTypes.coaches.length > 0) {
        let showCoaches;
        if(selectedLocation === 'all') {
            showCoaches = box.locations_box.some(location => getDisabledPagesProps(location).showCoach)
        } else {
            const location = box.locations_box.find(location => location.id === selectedLocation)
            showCoaches = getDisabledPagesProps(location).showCoach
        }
        if(showCoaches) {
            config['coaches'] = {
                options: filterTypes.coaches,
                isMulti: true,
                filterFunc: (obj, selected) => selected.some(item => obj.coach_fk === item || obj.second_coach_fk === item),
                selected: [],
                valueProp: 'id',
                labelProp: 'full_name',
                placeholder: t('staff-member'),
                enableSingleSelection: true,
                outerDisplay: false
            }
        }
    }

    if(filterTypes?.locations) {
        let locations = box.locations_box;
        if(filterTypes.locations.filterLocations) {
            locations = box.locations_box.filter(filterTypes.locations.filterLocations)
        }
        if(locations.length > 1) {
            if (!filterTypes?.locations?.hideAllLocations) {
                locations = [{id: 'all', location: t('all-locations')}, ...locations]
            }
            config['locations'] = {
                options: locations,
                isMulti: false,
                filterFunc: (obj, selected) => selected === 'all' ? true : (obj.locations_box_fk === selected),
                selected: selectedLocation ?? 'all',
                defaultValue: selectedLocation ?? 'all',
                valueProp: 'id',
                labelProp: 'location',
                outerDisplay: true,
                ...(filterTypes.locations.rest ?? {})
            }
        }
    }

    if(filterTypes?.boxCategories && filterTypes.boxCategories.length > 0) {
        config['boxCategories'] = {
            options: filterTypes.boxCategories,
            isMulti: true,
            filterFunc: (obj, selected) => selected.some(item => item === obj.box_category_fk),
            selected: [],
            valueProp: 'id',
            labelProp: 'name',
            placeholder: t('session-type'),
            outerDisplay: false
        }
    }

    if(filterTypes?.boxCategoriesGroups && filterTypes?.boxCategoriesGroups.length > 0) {
        config['groups'] = {
            options: filterTypes.boxCategoriesGroups,
            isMulti: true,
            filterFunc: (obj, selected) => selected.some(item => obj.box_categories.box_categories_groups.some(group => group.id === item)),
            selected: [],
            valueProp: 'id',
            labelProp: 'name',
            placeholder: t('categories'),
            enableSingleSelection: true,
            outerDisplay: false
        }
    }
    if(filterTypes?.spaces && filterTypes?.spaces.length > 0) {
        let showSpaces;
        if(selectedLocation === 'all') {
            showSpaces = box.locations_box.some(location => getDisabledPagesProps(location).showSpaceName)
        } else {
            const location = box.locations_box.find(location => location.id === selectedLocation)
            showSpaces = getDisabledPagesProps(location).showSpaceName
        }
        if(showSpaces) {
            config['spaces'] = {
                options: filterTypes.spaces,
                isMulti: true,
                filterFunc: (obj, selected) => selected.some(item => obj.spaces_id === item),
                selected: [],
                valueProp: 'id',
                labelProp: 'name',
                placeholder: t('spaces'),
                enableSingleSelection: true,
                outerDisplay: false
            }
        }
    }

    if(filterTypes?.online) {
        config['online'] = {
            options: [{label: t('online-show-all'), value: 'all'}, {label: t('only-online-sessions'), value: 'online'}, {label: t('only-frontal-sessions'), value: 'frontal'}],
            isMulti: false,
            filterFunc: (obj, selected) => selected === 'all' ? true : selected === 'online' ? (obj.live_link && obj.live_link !== '') : !obj.live_link,
            selected: 'all',
            defaultValue: 'all',
            isRadio: true,
            outerDisplay: false
        }
    }

    if(filterTypes?.sortOption) {
        config['sortOption'] = {
            options: [{label: t('sort-alphabet'), value: 'alphabet'}, {label: t('low-to-high'), value: 'low-to-high'}, {label: t('high-to-low'), value: 'high-to-low'}],
            isMulti: false,
            sortFunc: (items, sortType, orderByFunc) => {
                let sorted = sortAlphabetically(items, 'name');
                if(sortType === 'low-to-high' || sortType === 'high-to-low') {
                    sorted = orderByFunc(sorted, ['price'], [sortType === 'low-to-high' ? 'asc' : 'desc']);
                }
                return sorted;
            },
            selected: 'alphabet',
            defaultValue: 'alphabet',
            isRadio: true,
            filterSort: null,
            outerDisplay: false,
            ...(filterTypes.sortOption.rest ?? {})
        }
    }

    if(filterTypes?.customFields && filterTypes.customFields.length) {
        filterTypes.customFields.map(cf => (
            config[`cf-${cf.id}`] = {
                label: cf.name,
                options: cf.props.options,
                isMulti: true,
                filterFunc: (obj, selected) => selected.some(item => {
                    if (obj.custom_field_value.length && obj.custom_field_value.find(cfv => cfv.custom_field_id === cf.id)?.value === item)
                        return true;
                    else if (obj.series && obj.series.custom_field_value.length && obj.series?.custom_field_value?.find(cfv => cfv.custom_field_id === cf.id)?.value === item)
                        return true;
                    return false;
                }),
                selected: [],
                placeholder: t('select'),
                enableSingleSelection: true,
                outerDisplay: false
            }
        ))
    }

    return config
}