import React from 'react';
import {createRoot} from "react-dom/client";
import App from "./App";
import './index.scss'
import {ErrorBoundary} from "react-error-boundary";
import './src/helpers/i18n'

function Fallback({ error }) {

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}
createRoot(document.getElementById('root')).render(
    <ErrorBoundary fallbackRender={Fallback} onReset={(details) => {}}>
        <App/>
    </ErrorBoundary>
);