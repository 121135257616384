//reducers related constants

export const BOX = 'BOX';
export const SELECTED_LOCATION = 'SELECTED_LOCATION';
export const BOXES = 'BOXES';
export const LOCATIONSBOX = 'LOCATIONSBOX';
export const COACHES = 'COACHES';
export const MEMBERSHIPS = 'MEMBERSHIPS';
export const MEMBERSHIP_SCHEDULES = 'MEMBERSHIP_SCHEDULES';
export const MEMBERSHIP_SCHEDULES_DELETE = 'MEMBERSHIP_SCHEDULES_DELETE';
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE_IMAGE = 'DELETE_PROFILE_IMAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const PICTURE = 'PICTURE';
export const FRIEND = 'FRIEND';
export const ACTIVE_FRIEND_ID = 'ACTIVE_FRIEND_ID';
export const FRIEND_FRIENDSHIP_STATUS = 'FRIEND_FRIENDSHIP_STATUS';
export const FRIENDS_OF_FRIEND_ACTION = 'FRIENDS_OF_FRIEND_ACTION';
export const FRIENDS_OF_FRIEND_SCHEDULE_ACTION = 'FRIENDS_OF_FRIEND_SCHEDULE_ACTION';
export const SCHEDULES = 'SCHEDULES';
export const SCHEDULE_ACTION = 'SCHEDULE_ACTION';
export const SCHEDULE_CLEAR = 'SCHEDULE_CLEAR';
export const USER_CLASSES = 'USER_CLASSES';
export const APPOINTMENT_TYPES = 'APPOINTMENT_TYPES';
export const WEEKLY_SCHEDULES = 'WEEKLY_SCHEDULES';
export const USER_CLASSES_ACTION = 'USER_CLASSES_ACTION';
export const AVAILABILITIES = 'AVAILABILITIES';
export const AVAILABILITIES_PATCH = 'AVAILABILITIES_PATCH';
export const AVAILABILITIES_REMOVE_PATCH = 'AVAILABILITIES_REMOVE_PATCH';
export const USER_CLASSES_PATCH = 'USER_CLASSES_PATCH';
export const FEED = 'FEED';
export const REMOVE_FEED = 'REMOVE_FEED';
export const REMOVE_MESSAGES_CENTER_FROM_FEED = 'REMOVE_MESSAGES_CENTER_FROM_FEED';
export const REMOVE_ACTION_FROM_FEED = 'REMOVE_ACTION_FROM_FEED';
export const FEED_SCHEDULE_ACTION = 'FEED_SCHEDULE_ACTION';
export const MESSAGES_CENTER_COUNT = 'MESSAGES_CENTER_COUNT';
export const PATCH_AUTH_DETAILS = 'PATCH_AUTH_DETAILS';
export const REFRESH_TOKEN_UPDATE = 'REFRESH_TOKEN_UPDATE';
export const DEVICE_TOKEN = 'DEVICE_TOKEN';
export const HAS_SHOP = 'HAS_SHOP';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const FIRST_LOGIN = 'FIRST_LOGIN';
export const FRIEND_CONNECTIONS = 'FRIEND_CONNECTIONS';
export const FRIEND_CONNECTIONS_ACTION = 'FRIEND_CONNECTIONS_ACTION';
export const SELECT_BOX_ID = 'SELECT_BOX_ID';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const SCHEDULE_BY_DATE = 'SCHEDULE_BY_DATE';
export const SCHEDULE_USERS_CLASSES = 'SCHEDULE_USERS_CLASSES';
export const SCHEDULE_MEMBERSHIP_CLASSES = 'SCHEDULE_MEMBERSHIP_CLASSES';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const REMOVE_FRIEND_FROM_USER = 'REMOVE_FRIEND_FROM_USER';
export const ADD_FRIEND_TO_USER = 'ADD_FRIEND_TO_USER';
export const WHITE_LABEL_PROPERTIES = 'WHITE_LABEL_PROPERTIES';
export const WORKOUTS = 'WORKOUTS';
export const PATCH_WORKOUT = 'PATCH_WORKOUT';
export const USER_RESULTS = 'USER_RESULTS';
//MAY BE WORKAROUND FOR ALL NONE REDUCERS;
export const SCHEDULE_INSERTED = 'SCHEDULE_INSERTED';
export const SCHEDULE_REMOVED = 'SCHEDULE_REMOVED';


export const TOGGLE_SITE_REFETCH = 'TOGGLE_SITE_REFETCH';
export const IS_SITE = 'IS_SITE';
export const SITE_BOX = 'SITE_BOX';
export const SITE_WHITELABEL = 'SITE_WHITELABEL';
export const SITE_LANGUAGE = 'SITE_LANGUAGE';
export const SITE_WHITELABEL_LINK = 'SITE_WHITELABEL_LINK';
export const LOGOUT_USER = 'LOGOUT_USER';
export const HEADER_CONFIG = 'HEADER_CONFIG';
export const SITE_BOX_COACHES = 'SITE_BOX_COACHES';
//MAY BE WORKAROUND FOR ALL NONE REDUCERS;

export const FORMS = 'FORMS';
export const SHOP = 'SHOP';
export const PATCH_MEMBERSHIP_START_DATE = 'PATCH_MEMBERSHIP_START_DATE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const TAXES = 'TAXES';
export const CURRENCY_SYMBOL = 'CURRENCY_SYMBOL';
export const SET_SHOP_LOCATIONS_BOX = 'SET_SHOP_LOCATIONS_BOX';
export const SET_ACTIVE_USERS_BOXES = 'SET_ACTIVE_USERS_BOXES';
export const VALIDATE_PROMO_CODE = 'VALIDATE_PROMO_CODE';

// BOX TYPES:
export const PERSONAL_TRAINER_TYPE_FK = 13;

//REFERERS- APP/PLUGIN/SITE DISTINGUISH:
export const SITE_REFERER = 'SITE';
export const PLUGIN_REFERER = 'PLUGIN';
export const APP_REFERER = 'APP';
export const REFERER = 'REFERER';

//MINISITE HEADER CONFIG OPTIONS:
export const BOOK_APPOINTMENT_PROCESS = 'BOOK_APPOINTMENT';
export const CHOOSE_SERVICE_STEP = 'CHOOSE_SERVICE_STEP';
export const CHOOSE_SLOT_STEP = 'CHOOSE_SLOT_STEP';
export const BOOKED_DESKTOP_STEP = 'BOOKED_DESKTOP_STEP';
export const GROUP_SESSION_PROCESS = 'GROUP_SESSION_PROCESS';
export const PAY_FOR_SLOT = 'PAY_FOR_SLOT';
export const ISRAEL_PAYMENT_RES = 'ISRAEL_PAYMENT_RES';
export const ISRAEL_PAYMENT_RES_CLEANUP = 'ISRAEL_PAYMENT_RES_CLEANUP';
export const ADD_BOX_TO_SITE_DATA = 'ADD_BOX_TO_SITE_DATA';
export const CHANGE_ACTIVE_BOX = 'CHANGE_ACTIVE_BOX';
export const LOGOUT_BOX_USER = 'LOGOUT_BOX_USER';
export const LOGIN_BOX_USER = 'LOGIN_BOX_USER';
export const UPDATE_REGISTER_PROCESS_STEP = 'UPDATE_REGISTER_PROCESS_STEP';
export const UPDATE_QUERY_STRING = 'UPDATE_QUERY_STRING';
export const UPDATE_USER_CREATED = 'UPDATE_USER_CREATED';


export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_MODAL_SIMPLE = 'SHOW_MODAL_SIMPLE';
export const HIDE_MODAL = 'HIDE_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';


export const ACTION_EXPIRED_MEMBERSHIP = "expiredMembership";
export const ACTION_FUTURE_MEMBERSHIP = "futureMembership";
export const ACTION_EPIDEMIC_STATEMENT = "epidemicStatement";
export const ACTION_WELCOME_CARD = "welcomeCard";
export const ACTION_EXPLAIN_CARD = "explainCard";
export const ACTION_MEDICAL_CERT_CARD = "medicalCertCard";
export const ACTION_RENEW_MEMBERSHIP = "renewMembership";
export const ACTION_ENDING_MEMBERSHIP = "endingMembership";
export const ACTION_MY_BIRTHDAY = "myBirthday";
export const ACTION_MY_BALLOONS = "myBalloons";
export const ACTION_FRIEND_BIRTHDAY = "friendBirthday";
export const ACTION_BOX_MESSAGE = "boxMessage";
export const ACTION_SCHEDULE_USER_STATUS = "scheduleUserStatus";
export const ACTION_USER_YEARLY_SUMMARY = "userYearlySummary";
export const ACTION_LOGBOOK = "logbook";
export const ACTION_SCHEDULE_UPCOMING = "scheduleUpcoming";
export const ACTION_SCHEDULE_CANCELLED = "scheduleCancelled";
export const ACTION_SCHEDULE_ENTERED_FROM_STANDBY = "scheduleEnteredFromStandby";
export const ACTION_THANKS_FOR_BALLOONS = 'thanksForBalloons';
export const ACTION_FRIEND_SENT_BALLOON = 'friendSentBalloon';
export const ACTION_FRIEND_REQUEST = "friendRequest";
export const ACTION_FRIEND_REQUEST_ACCEPTED = "friendRequestAccepted";
export const ACTION_SCHEDULE_INVITATION = "scheduleInvitation";
export const ACTION_SCHEDULE_INVITATION_ACCEPTED = "scheduleInvitationAccepted";
export const ACTION_TODAY_WORKOUT = "todayWorkout";

export const LOADING_PAGE = "LOADING_PAGE";
export const LOADING_SECTION = "LOADING_SECTION";
export const DB_DATE_FORMAT = "YYYY-MM-DD";
export const DB_TIME_FORMAT = "HH:mm:ss";

export const APPOINTMENT_TYPE_STAFF = "appointment-staff";
export const APPOINTMENT_TYPE_SPACE = "appointment-space";

