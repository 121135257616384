import React, {useEffect, useMemo, useState} from 'react';
import {
    FlexColumnSection,
    FlexRowSection,
    SmallHeader,
} from "../../styles/globalStyles";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {t} from "i18next";
import {Divider} from "antd";
import PersonalDetails from "./PersonalDetails";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import UserAvatar from "../../components/UserAvatar";
import dayjs from "dayjs";
import {useSelector, useDispatch} from "react-redux";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {updateError} from "@arboxappv4/shared/src/redux/stepperSlice";

const TrialPersonalDetails = (props) => {
    const { values, setFieldValue, handleSubmit, ...rest } = props;
    const language = useSelector(state => state.site.language)
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const box = useSelector(state => state.site.box)

    const [sessionDetailsComp, setSessionDetailsComp] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);

    useEffect(() => {
        console.log('TrialPersonalDetails values',values)
    }, [values]);

    useEffect(() => {
        if(values.schedule) {
            setSessionDetailsComp(getSessionDetails())
        } else {
            setSessionDetailsComp(null)
        }
    }, [values.schedule]);

    useEffect(() => {
        if(submitted) {
            console.log('submitted', values)
            personalDetailsSubmit()
            setSubmitted(false)
        }
    }, [submitted]);


    const getSessionDetails = () => {
        const locationRowInfo = getLocationRowInfo()

        return (
            <FlexColumnSection gap={isMobile ? '10px' : '15px'} flex={'unset'} padding={'10px 0'}>
                <FlexColumnSection gap={'10px'} flex={'unset'}>
                    <FlexRowSection gap={'10px'}>
                        <span>{dayjs(values.schedule.date, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`dddd, ${language?.code === 'he' ? 'D MMMM' : 'MMMM D'}`)}</span>
                        <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'}/>
                        <span>{dayjs(values.schedule.time, 'HH:mm').format(globalLocation.time_format)} - {dayjs(values.schedule.end_time, 'HH:mm').format(globalLocation.time_format)}</span>
                    </FlexRowSection>
                    {values.schedule.coach && <UserAvatar user={values.schedule.coach} nameType={'full'}/>}
                    {values.schedule.second_coach &&
                        <UserAvatar user={values.schedule.second_coach} nameType={'full'}/>}
                    <FlexRowSection gap={'10px'} mobile={isMobile}>
                        {locationRowInfo.map((info, i) => (
                            <>
                                <span>{info}</span>
                                {(i + 1 < locationRowInfo.length) &&
                                    <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack}
                                                     className={'dot-icon'}/>}
                            </>
                        ))}
                    </FlexRowSection>
                    <span><span style={{fontWeight: 500}}>{t('trial-class-price')}: </span><span>{values.cart?.price > 0 ? `${currencySymbol} ${values.cart.price}` : t('free')}</span></span>
                </FlexColumnSection>
                {values.schedule.box_categories.bio && <span><span style={{fontWeight: 500}}>{t('description')}: </span><span>{values.schedule.box_categories.bio}</span></span>}
            </FlexColumnSection>
        )
    }

    const getLocationRowInfo = () => {
        let info = []
        if (box.locations_box.length > 1) {
            info.push(values.schedule.locations_box.location)
        }
        if(values.schedule.spaces) {
            info.push(values.schedule.spaces.name)
        }
        if(values.schedule.live_link) {
            info.push(t('online-session'))
        }
        return info
    }

    const personalDetailsSubmit = async () => {
        try {
            await apiAction('site/isUserValidForTrial', 'post', {schedule_id: values.schedule.id, user_id: values.lead_id});
            handleSubmit()
        } catch(e) {
            console.log('personalDetailsSubmit error', e)
            dispatch(updateError(e.error?.messageToUser))
        }
    }

    return (
        <div style={{display: 'flex', flex: 1, overflow: isMobile ? 'auto' : 'hidden'}}>
            {
                isMobile ?
                    <FlexColumnSection gap={'10px'} height={'100%'} justify={'space-between'} overflow={'auto'}>
                        <FlexColumnSection flex={'unset'} overflow={'auto'}>
                            <div style={{padding: '0 16px'}}>
                                <SmallHeader>{t('trial-session-details')}</SmallHeader>
                                {sessionDetailsComp}
                            </div>
                            <div style={{padding: '0 16px'}}><Divider style={{margin: '15px 0'}}/></div>
                            <div>
                                <div style={{padding: '0 16px 10px'}}><SmallHeader>{t('trial-contact-details')}</SmallHeader></div>
                                <PersonalDetails values={values}
                                                 setFieldValue={setFieldValue}
                                                 hideLocation={true}
                                                 isInnerComponent={true}
                                                 handleSubmit={() => setSubmitted(true)}
                                                 nextBtnText={t('trial-personal-details-next')}
                                                 {...rest}
                                />
                            </div>
                        </FlexColumnSection>
                    </FlexColumnSection>
                    :
                    <FlexRowSection gap={'20px'} align={'start'}>
                        <DesktopContentBox>
                            <div style={{padding: '16px', overflow: 'auto'}}>
                                <SmallHeader>{t('trial-session-details')}</SmallHeader>
                                {sessionDetailsComp}
                            </div>
                        </DesktopContentBox>
                        <DesktopContentBox gap={'0'}>
                            <div style={{padding: '16px 16px 0'}}><SmallHeader>{t('trial-contact-details')}</SmallHeader></div>
                            <div style={{height: 'calc(100% - 50px)'}}>
                            <PersonalDetails values={values}
                                             setFieldValue={setFieldValue}
                                             hideLocation={true}
                                             isInnerComponent={true}
                                             handleSubmit={() => setSubmitted(true)}
                                             nextBtnText={t('trial-personal-details-next')}
                                             {...rest}
                            />
                            </div>
                        </DesktopContentBox>
                    </FlexRowSection>
            }
        </div>
    );
};

export default TrialPersonalDetails;


const DesktopContentBox = styled(FlexColumnSection)`
    background-color: white;
    gap: ${({gap}) => gap ?? '15px'};
    border-radius: 5px;
    width: ${({width}) => width ?? 'unset'};
    height: 100%;
`;