import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 150 150",
        }}
        viewBox="0 0 150 150"
        {...props}
    >
        <style>
            {
                ".st3{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
            }
        </style>
        <circle
            cx={75}
            cy={75}
            r={72.5}
            style={{
                fill: "#ecfaf1",
                stroke: "#fff",
                strokeWidth: 5,
            }}
        />
        <path
            d="M112.7 103H36.3c-2.9 0-5.3-2.4-5.3-5.3V51.5c0-2.9 2.4-5.3 5.3-5.3h76.4c2.9 0 5.3 2.4 5.3 5.3v46.2c0 2.9-2.3 5.3-5.3 5.3z"
            style={{
                fill: "#fff",
                stroke: "#212121",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <path
            d="M77.2 95.9H38.1V53.3h39.1v42.6z"
            style={{
                fill: "#f1f1f1",
            }}
        />
        <path
            d="M63 69.2c0 2.9-2.4 5.3-5.3 5.3s-5.3-2.4-5.3-5.3 2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3zM66.5 85.2H48.8v-1.8c0-4.9 4-8.9 8.9-8.9s8.9 4 8.9 8.9v1.8zM109.2 67.5H84.3V55h24.9v12.5zM84.3 94.1h24.9M84.3 85.2h24.9M84.3 76.4h24.9"
            className="st3"
        />
        <path
            d="M112.6 114.8c-7 0-12.7-5.7-12.7-12.7 0-7 5.7-12.7 12.7-12.7 7 0 12.7 5.7 12.7 12.7-.1 7.1-5.7 12.7-12.7 12.7z"
            style={{
                fill: "#43cc6b",
            }}
        />
        <path
            d="m109.5 102.6 3.1 2.3 3.9-4.7"
            style={{
                fill: "none",
                stroke: "#fff",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)
export default SvgComponent
