import React, {useState} from 'react';
import { Input } from 'antd';
import {t} from "i18next";
const { TextArea } = Input;
import "../waiver.scss"

const OpenQuestion = (props) => {
    const {onSelect, error, answer} = props;
    const [text, setText] = useState(answer ?? '');

    const textChanged = (txt) => {
        setText(txt)
        onSelect(txt)
    }
    
    return (
        <TextArea rows={4} placeholder={t('open-question-placeholder')} onChange={(val) => textChanged(val.target.value)} value={text} className={error ? 'text-area-error' : ''}/>
    )
}

export default OpenQuestion;
