import React, {useMemo} from 'react';
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {useSelector} from "react-redux";
import {FlexColumnSection, FlexRowSection} from "../../../styles/globalStyles";
import {getMembershipPriceText, isLocationIL, sortCourseEventDays} from "../../../helpers/functions";
import dayjs from "dayjs";
import {t} from "i18next";
import UserAvatar from "../../../components/UserAvatar";

const CourseInfo = (props) => {
    const { course } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const box = useSelector(state => state.site.box)
    const {isMobile} = useScreenSize()

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);

    if(!course) return <></>

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '15px'} flex={'unset'}>
            <span>
                {getMembershipPriceText(course.membership_types[0], currencySymbol)}
                {(isLocationIL() && course.membership_types[0].max_installments > 1 && course.membership_types[0].price > 0) && <span> ({t('up-to-installments', {num: course.membership_types[0].max_installments})}) </span>}
            </span>
            <FlexRowSection gap={'10px'}>
                <div>{dayjs(course.start_date, 'YYYY-MM-DD').format(globalLocation.date_format)} - {course.end_date ? dayjs(course.end_date, 'YYYY-MM-DD').format(globalLocation.date_format) : t('unlimited')}</div>
            </FlexRowSection>
            {course.coach && <UserAvatar user={course.coach} nameType={'full'}/>}
            {course.second_coach && <UserAvatar user={course.second_coach} nameType={'full'}/>}
            {box.locations_box.length > 1 && <span>{t('location')}: {course.locations_box.location}</span>}
        </FlexColumnSection>
    );
};

export default CourseInfo;