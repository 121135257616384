import React, {useState} from 'react';
import {Collapse} from "antd";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import './UI.scss'
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from 'styled-components'
import {BlackBgHoverOutlineBtn, BoldCollapseText, DisabledCta} from "../../styles/globalStyles";
import {t} from "i18next";
import useScreenSize from "../../helpers/hooks/useScreenSize";
const { Panel } = Collapse;


const CustomCollapse = (props) => {
    const { headerText, headerInfo, children, onBtnClick, headerWidth, ctaText, disableExpand, ctaWidth, background, restStyles } = props;
    const [isOpen, setIsOpen] = useState(false);
    const {isMobile} = useScreenSize()

    const renderHeader = () => {
        return (
            <Wrapper isMobile={isMobile}>
                <Info isMobile={isMobile} restStyles={restStyles}>
                    {isMobile ?
                        <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                            <Header isMobile={isMobile}>{headerText}</Header>
                            {isMobile && !disableExpand && <FontAwesomeIcon icon={light('circle-chevron-down')} color={Colors.siteBlack} flip={isOpen ? 'vertical' : 'horizontal'}/>}
                        </div>
                        :
                        <Header isMobile={isMobile} headerWidth={headerWidth}>{headerText}</Header>
                    }
                    {headerInfo}
                </Info>
                {disableExpand === 'disabled' && <DisabledCta width={ctaWidth}>{ctaText}</DisabledCta>}
                {(!isMobile || !isOpen) && disableExpand !== 'disabled' && <BlackBgHoverOutlineBtn type={isMobile ? 'primary' : 'default'} width={isMobile ? '100%' : ctaWidth ?? 'auto'} onClick={onBtnClick}>{ctaText ??t('purchase')}</BlackBgHoverOutlineBtn>}
            </Wrapper>
        )
    }

    return (
        <Collapse collapsible={disableExpand ?? "header"}
                  onChange={(e) => setIsOpen(e.length > 0)}
                  className={'custom-collapse-wrapper'}
                  bordered={false}
                  style={{backgroundColor: background ?? (isOpen && !isMobile ? Colors.fullyBooked : Colors.white)}}
                  expandIcon={({ isActive }) => isMobile || disableExpand ? null : <FontAwesomeIcon icon={light('circle-chevron-down')} color={Colors.siteBlack} flip={isActive ? 'vertical' : 'horizontal'}/>}>
            <Panel header={renderHeader()} key="1">
                {children}
                {disableExpand === 'disabled' && <DisabledCta>{ctaText}</DisabledCta>}
                {isMobile && isOpen && disableExpand !== 'disabled' && <BlackBgHoverOutlineBtn width={isMobile ? '100%' : 'auto'} type={isMobile ? 'primary' : 'default'} onClick={onBtnClick}>{ctaText ?? t('purchase')}</BlackBgHoverOutlineBtn>}
            </Panel>
        </Collapse>
    );
};

export default CustomCollapse;

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ? 'column' : 'row'};
    justify-content: space-between;
    align-items: center;
    width: inherit;
    gap: 20px;
`;

const Header = styled(BoldCollapseText)`
    width: ${({isMobile, headerWidth}) => isMobile ? '90%' : headerWidth ?? '35%'};
    max-width: ${({isMobile, headerWidth}) => isMobile ? '90%' : headerWidth ?? '50%'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Info = styled.div`
    gap: ${({isMobile}) => isMobile ? '5px' : '30px'};
    display: flex;
    flex: 1;
    flex-direction: ${({isMobile}) => isMobile ? 'column' : 'row'};
    width: 100%;
    align-items: center;
    ${({restStyles}) => restStyles ?? ''}
`;

