import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 150 150",
        }}
        viewBox="0 0 150 150"
        {...props}
    >
        <style>
            {
                ".st2{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926}"
            }
        </style>
        <circle
            cx={75}
            cy={75}
            r={72.5}
            style={{
                fill: "#e0e0e0",
                stroke: "#fff",
                strokeWidth: 5,
            }}
        />
        <path
            d="M40.5 38.6h69.6c1.9 0 3.5 1.6 3.5 3.5v69.4c0 1.9-1.6 3.5-3.5 3.5H40.5c-1.9 0-3.5-1.6-3.5-3.5V42.1c0-1.9 1.6-3.5 3.5-3.5z"
            style={{
                fill: "#fff",
                stroke: "#212121",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 22.926,
            }}
        />
        <path
            d="M54.4 45.5c-2.4 0-4.3-1.9-4.3-4.3v-6.9c0-2.4 2-4.3 4.3-4.3 2.4 0 4.3 1.9 4.3 4.3v3.5M75.3 45.5c-2.4 0-4.3-1.9-4.3-4.3v-6.9c0-2.4 2-4.3 4.3-4.3 2.4 0 4.3 1.9 4.3 4.3v3.5M96.2 45.5c-2.4 0-4.3-1.9-4.3-4.3v-6.9c0-2.4 2-4.3 4.3-4.3 2.4 0 4.3 1.9 4.3 4.3v3.5M37.9 52.5h74.8"
            className="st2"
        />
    </svg>
)
export default SvgComponent
