import React, {useEffect, useState} from 'react';
import {BoldCollapseText, FlexColumnSection, SeparatorHorizontal} from "../../../styles/globalStyles";
import './ChildSelection.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {t} from "i18next";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import ChildDetails from "./ChildDetails";
import {useDispatch, useSelector} from "react-redux";
import {updateFlags} from "@arboxappv4/shared/src/redux/stepperSlice";
import dayjs from "dayjs";

const ChildSelection = (props) => {
    const { values, setFieldValue, handleSubmit, errors } = props;
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const [childrenArr, setChildrenArr] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        setFieldValue('isNewChild', false)
        setFieldValue('child', {})
        dispatch(updateFlags({isNewChild: false}))
    }, []);

    useEffect(() => {
        if(userBox && userBox.group_connection) {
            const children = userBox.group_connection.group_members.filter(groupConnection => groupConnection.id !== userBox.group_connection.users_boxes_id)
            setChildrenArr(children)
        }
    }, [userBox]);

    useEffect(() => {
        if(values.child?.child_id) {
            handleSubmit()
        }
    }, [values.child?.child_id]);

    const onChildSelected = (groupConnection) => {
        setFieldValue('isNewChild', false)
        setFieldValue('child.child_id', groupConnection.user_fk)
    }

    const onAddChild = () => {
        setFieldValue('isNewChild', true)
        dispatch(updateFlags({isNewChild: true}))
    }

    if(!childrenArr) return null

    return (
        <div className={'child-selection-wrapper'}>
            {values?.isNewChild ? <ChildDetails {...props}/> :
            <FlexColumnSection align={'start'} padding={'0 5px'}>
                <div className={'children-wrapper'}>
                    {childrenArr.map(item => (
                        <div onClick={() => onChildSelected(item)} className={'white-option'} key={`child-selection-${item.id}`}>
                            <BoldCollapseText>{item.full_name}</BoldCollapseText>
                            <div className={'child-info'}>
                                {item.birthday && <div className={'child-info-text'}>{t('age-label')}: {dayjs().diff(item.birthday, 'year')}</div>}
                                {item.personal_id && <div className={'child-info-text'}>{t('id-label')}: {item.personal_id}</div>}
                            </div>
                        </div>
                    ))}
                </div>
                <SeparatorHorizontal margin={'20px 0'}/>
                <div onClick={onAddChild} style={{width: '100%'}}>
                    <div className={'white-option add-child'}>
                        <FontAwesomeIcon icon={solid('plus')} color={Colors.siteBlack}/>
                        <BoldCollapseText>{t('new-child')}</BoldCollapseText>
                    </div>
                </div>
            </FlexColumnSection>}
        </div>
    );
};

export default ChildSelection;
