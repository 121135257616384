import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 150 150",
        }}
        viewBox="0 0 150 150"
        {...props}
    >
        <style>
            {
                ".st1{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st2{fill:#fff}"
            }
        </style>
        <circle
            cx={75}
            cy={75}
            r={72.5}
            style={{
                fill: "#ecfaf1",
                stroke: "#fff",
                strokeWidth: 5,
            }}
        />
        <path
            d="M48.9 53v-4.6c0-7.1 5.5-12.8 12.2-12.8 6.8 0 12.2 5.7 12.2 12.8V53"
            className="st1"
        />
        <path d="M47.6 112.4h-4c-3.2 0-5.9-2.6-5.9-5.8V53.2h9.9" className="st2" />
        <path
            d="M47.6 112.4h-4c-3.2 0-5.9-2.6-5.9-5.8V53.2h9.9v59.2z"
            className="st1"
        />
        <path d="M88.6 112.4H47.3V53.2h47.1v59.3" className="st2" />
        <path d="M88.6 112.4H47.3V53.2h47.1v59.3" className="st1" />
        <path
            d="M58.8 62.7v-15c0-6.7 5.5-12.2 12.2-12.2 6.8 0 12.2 5.4 12.2 12.2v15"
            className="st1"
        />
        <path
            d="M82.9 113.5h22.8c3.1 0 5.6-2.5 5.6-5.6V79.7h-34v28.2c0 3.1 2.5 5.6 5.6 5.6z"
            style={{
                fill: "#fff",
                stroke: "#212121",
            }}
        />
        <path d="M87.6 87v-8.7a6.7 6.7 0 0 1 13.4 0V87" className="st1" />
        <path
            d="M112.6 114.8c-7 0-12.7-5.7-12.7-12.7 0-7 5.7-12.7 12.7-12.7 7 0 12.7 5.7 12.7 12.7-.1 7.1-5.7 12.7-12.7 12.7z"
            style={{
                fill: "#43cc6b",
            }}
        />
        <path
            d="m109.5 102.6 3.1 2.3 3.9-4.7"
            style={{
                fill: "none",
                stroke: "#fff",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)
export default SvgComponent
