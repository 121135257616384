import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    flowName: null,
    steps: null,
    currStep: null,
    flowRes: null,
    error: null,
    asyncData: {},
    flags: {},
    targetName: null
}

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState: initialState,
    reducers: {
        updateStepper: (state, data) => {
            state.flowName = data.payload.flowName ?? state.flowName
            state.steps = data.payload.steps ?? state.steps
            state.currStep = data.payload.currStep ?? state.currStep
            state.flowRes = null
            state.error = null
            state.targetName = null
            state.flags = data.payload.flags ?? state.flags
        },
        updateCurrStep: (state, data) => {
            if(state.currStep !== data.payload){
                state.currStep = data.payload;
            }
        },
        updateFlowRes: (state, data) => {
            state.flowRes = data.payload;
        },
        updateAsyncData: (state, data) => {
            state.asyncData = data.payload;
        },
        updateFlags: (state, data) => {
            state.flags = {...state.flags, ...data.payload}
        },
        cleanStepper: (state) => {
            state.flowName = null
            state.steps = null
            state.currStep = null
            state.flowRes = null
            state.error = null
            state.targetName = null
        },
        updateError: (state, action) => {
            state.error = action.payload
        },
        setTargetName: (state, action) => {
            state.targetName = action.payload
        }
    }
})

export const { updateStepper, updateCurrStep, cleanStepper, updateFlowRes, updateAsyncData, updateFlags, updateError, setTargetName } = stepperSlice.actions

export default stepperSlice.reducer
