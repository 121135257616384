import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={15}
        fill="none"
        {...props}
    >
        <path
            fill="#FF883E"
            d="M0 2.617C0 1.617.807.805 1.8.805h9c.993 0 1.8.812 1.8 1.812v2.906a4.94 4.94 0 0 0-2.132 1.254.673.673 0 0 0-.568-.308H5.4a.676.676 0 0 0-.675.68c0 .376.3.68.675.68h4.26c-.238.418-.42.874-.533 1.359H5.4a.676.676 0 0 0-.675.68c0 .376.3.679.675.679h3.606a4.982 4.982 0 0 0 1.127 2.945H1.8c-.993 0-1.8-.813-1.8-1.812V2.617ZM1.8 4.43c0 .501.402.906.9.906s.9-.405.9-.906a.902.902 0 0 0-.9-.907c-.498 0-.9.405-.9.907Zm0 2.718c0 .502.402.907.9.907s.9-.405.9-.907a.902.902 0 0 0-.9-.906c-.498 0-.9.405-.9.906Zm0 2.72c0 .5.402.905.9.905s.9-.405.9-.906a.902.902 0 0 0-.9-.906c-.498 0-.9.405-.9.906ZM4.725 4.43c0 .376.3.68.675.68h4.5a.676.676 0 0 0 .675-.68c0-.377-.3-.68-.675-.68H5.4a.676.676 0 0 0-.675.68ZM9.9 10.32c0-2.251 1.814-4.078 4.05-4.078 2.236 0 4.05 1.827 4.05 4.078 0 2.252-1.814 4.078-4.05 4.078-2.236 0-4.05-1.826-4.05-4.078Zm3.6-1.812v1.812c0 .25.203.453.45.453h1.35a.453.453 0 0 0 0-.906h-.9v-1.36a.453.453 0 0 0-.45-.452.453.453 0 0 0-.45.453Z"
        />
    </svg>
)
export default SvgComponent
