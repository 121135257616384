import React, {useEffect, useState} from 'react';

const Quantity = (props) => {
    const { onChange, max } = props;
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        onChange(counter)
    }, [counter]);

    return (
        <div className={'quantity-wrapper'}>
            <div className={'sign'} onClick={() => setCounter(prev => prev === 1 ? prev : prev - 1)}>-</div>
            <div>{counter}</div>
            <div className={'sign'} onClick={() => setCounter(prev => (max && prev === max) ? prev : prev + 1)}>+</div>
        </div>
    );
};

export default Quantity;