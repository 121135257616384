import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiAction} from "../helpers/HTTP";
import {setLoadingType} from "./siteSlice";
import {LOADING_PAGE} from "../constants/constants";
import {resetUser} from "./userSlice";

const initialState = {
    loggedIn: false,
    isAuthenticating: false,
    token: null,
    refreshToken: null,
    deviceToken: null,
    hasStore: false,
    data: null,
    error: null,
}

const handleAuthPending = (state) => {
    state.isAuthenticating = true;
    state.loggedIn = false;
    state.error = null;
};

const handleAuthFulfilled = (state, action) => {
    state.isAuthenticating = false;
    state.loggedIn = true;
    state.error = null;
    state.data = action.payload.data;
    state.token = action.payload.data.token;
    state.refreshToken = action.payload.data.refreshToken;
};

const handleAuthRejected = (state, action) => {
    state.isAuthenticating = false;
    state.loggedIn = false;
    state.token = null;
    state.data = null;
    state.refreshToken = null;
    state.error = action.payload.error.messageToUser;
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        updateRefreshToken: (state, action) => {
            state.refreshToken = action.payload
        },
        resetError: (state) => {
            state.error = null
        },
        addTokenAfterRegister: (state, action) => {
            state.isAuthenticating = false
            state.loggedIn = true
            state.error = null
            state.data = action.payload
            state.token = action.payload.token
            state.refreshToken = action.payload.refreshToken
        }
    },
    extraReducers: builder => {
        builder.addCase(login.pending, handleAuthPending)
        builder.addCase(login.fulfilled, handleAuthFulfilled)
        builder.addCase(login.rejected, handleAuthRejected)

        builder.addCase(otpLogin.pending, handleAuthPending)
        builder.addCase(otpLogin.fulfilled, handleAuthFulfilled)
        builder.addCase(otpLogin.rejected, handleAuthRejected)

        builder.addCase(logout.fulfilled, (state) => initialState)

    }
})

export const { updateRefreshToken, resetError, addTokenAfterRegister } = authSlice.actions

export default authSlice.reducer


export const login = createAsyncThunk('login', async (params, {rejectWithValue, dispatch}) => {
    try {
        dispatch(setLoadingType(LOADING_PAGE))
        const response = await apiAction('user/siteLogin', 'post', params);
        dispatch(setLoadingType(null))
        return response
    } catch (e) {
        dispatch(setLoadingType(null))
        return rejectWithValue(e);
    }
})

export const logout = createAsyncThunk('logout', async (params, {rejectWithValue, dispatch}) => {
    try {
        dispatch(resetUser())
        return null
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const otpLogin = createAsyncThunk('otpLogin', async (params, {rejectWithValue, dispatch}) => {
    try {
        return await apiAction('user/mfa/login', 'post', params);
    } catch (e) {
        return rejectWithValue(e);
    }
})