import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 129 39",
        }}
        viewBox="0 0 129 39"
        {...props}
    >
        <style>
            {
                ".st0{clip-path:url(#SVGID_00000104677826310437985030000014097006448075533481_)}"
            }
        </style>
        <defs>
            <path id="SVGID_1_" d="M.7.7H129v38H.7z" />
        </defs>
        <clipPath id="SVGID_00000155851870199418921720000010710072308628101776_">
            <use
                xlinkHref="#SVGID_1_"
                style={{
                    overflow: "visible",
                }}
            />
        </clipPath>
        <g
            style={{
                clipPath:
                    "url(#SVGID_00000155851870199418921720000010710072308628101776_)",
            }}
        >
            <path
                d="M124.2 38.7H5.4C2.8 38.7.7 36.6.7 34V5.4C.7 2.8 2.8.7 5.4.7h118.7c2.6 0 4.8 2.1 4.8 4.7v28.5c0 2.6-2.1 4.8-4.7 4.8z"
                style={{
                    fill: "#100f0d",
                }}
            />
            <path
                d="M124.2.7H5.4C2.8.7.7 2.8.7 5.4v28.5c0 2.6 2.1 4.7 4.7 4.7h118.7c2.6 0 4.8-2.1 4.8-4.7V5.4c0-2.6-2.1-4.7-4.7-4.7zm0 .7c2.2 0 4 1.8 4 4v28.5c0 2.2-1.8 4-4 4H5.4c-2.2 0-4-1.8-4-4V5.4c0-2.2 1.8-4 4-4h118.8z"
                style={{
                    fill: "#a2a2a1",
                }}
            />
            <path
                d="M102.3 29.2h1.8V17.3h-1.8v11.9zm15.9-7.6-2 5.1h-.1l-2.1-5.1h-1.9l3.2 7.2-1.8 4h1.8l4.9-11.2h-2zm-10 6.2c-.6 0-1.4-.3-1.4-1 0-.9 1-1.3 1.9-1.3.8 0 1.1.2 1.6.4-.1 1.1-1.1 1.9-2.1 1.9zm.2-6.5c-1.3 0-2.6.6-3.2 1.8l1.6.7c.3-.7 1-.9 1.6-.9.9 0 1.8.5 1.9 1.5v.1c-.3-.2-1-.5-1.8-.5-1.7 0-3.4.9-3.4 2.7 0 1.6 1.4 2.6 2.9 2.6 1.2 0 1.9-.5 2.3-1.2h.1v.9h1.7v-4.6c-.1-1.9-1.7-3.1-3.7-3.1zm-11 1.7h-2.5v-4.1h2.5c1.3 0 2.1 1.1 2.1 2 0 1-.7 2.1-2.1 2.1zm0-5.7h-4.2v11.9H95v-4.5h2.5c2 0 3.9-1.4 3.9-3.7-.1-2.3-2-3.7-4-3.7zM74.2 27.8c-1.2 0-2.2-1-2.2-2.4s1-2.5 2.2-2.5c1.2 0 2.2 1 2.2 2.5 0 1.4-1 2.4-2.2 2.4zm2.1-5.6c-.5-.5-1.2-.9-2.2-.9-2 0-3.9 1.8-3.9 4.1s1.9 4 3.9 4c1 0 1.7-.4 2.1-.9h.1v.6c0 1.5-.8 2.4-2.2 2.4-1.1 0-1.8-.8-2-1.4l-1.5.6c.4 1.1 1.6 2.4 3.6 2.4 2.1 0 3.8-1.2 3.8-4.2v-7.3h-1.7v.6zm2.9 7H81V17.3h-1.8v11.9zm4.4-4c0-1.6 1.2-2.4 2.1-2.4.7 0 1.3.4 1.5.9l-3.6 1.5zm5.5-1.3c-.3-.9-1.4-2.6-3.5-2.6s-3.8 1.6-3.8 4c0 2.3 1.7 4 4 4 1.9 0 2.9-1.1 3.4-1.8l-1.4-.9c-.5.7-1.1 1.1-2 1.1-.9 0-1.5-.4-2-1.2l5.4-2.2-.1-.4zM46 22.6v1.7h4.1c-.1 1-.4 1.7-.9 2.2-.6.6-1.5 1.3-3.2 1.3-2.5 0-4.5-2-4.5-4.6 0-2.5 2-4.6 4.5-4.6 1.4 0 2.4.5 3.1 1.2l1.2-1.2c-1-1-2.4-1.7-4.3-1.7-3.5 0-6.4 2.8-6.4 6.3s2.9 6.3 6.4 6.3c1.9 0 3.3-.6 4.4-1.8 1.1-1.1 1.5-2.7 1.5-4 0-.4 0-.8-.1-1.1H46zm10.6 5.2c-1.2 0-2.3-1-2.3-2.4 0-1.5 1.1-2.4 2.3-2.4 1.2 0 2.3 1 2.3 2.4-.1 1.4-1.1 2.4-2.3 2.4zm0-6.5c-2.2 0-4.1 1.7-4.1 4s1.8 4 4.1 4c2.2 0 4.1-1.7 4.1-4-.1-2.3-1.9-4-4.1-4zm8.8 6.5c-1.2 0-2.3-1-2.3-2.4 0-1.5 1.1-2.4 2.3-2.4 1.2 0 2.3 1 2.3 2.4s-1.1 2.4-2.3 2.4zm0-6.5c-2.2 0-4.1 1.7-4.1 4s1.8 4 4.1 4c2.2 0 4.1-1.7 4.1-4s-1.9-4-4.1-4z"
                style={{
                    fill: "#fff",
                }}
            />
            <path
                d="M20.4 19.1 10.2 29.9c.3 1.2 1.4 2 2.6 2 .5 0 1-.1 1.4-.4l11.4-6.6-5.2-5.8z"
                style={{
                    fill: "#db4437",
                }}
            />
            <path
                d="m30.6 17.3-4.9-2.9-5.5 4.9 5.6 5.6 4.9-2.8c.9-.5 1.4-1.4 1.4-2.4-.1-1-.7-1.9-1.5-2.4z"
                style={{
                    fill: "#f4b400",
                }}
            />
            <path
                d="M10.2 9.5c-.1.2-.1.5-.1.7v19c0 .2 0 .5.1.7l10.5-10.5-10.5-9.9z"
                style={{
                    fill: "#4285f4",
                }}
            />
            <path
                d="m20.4 19.7 5.2-5.2-11.3-6.7c-.4-.2-.9-.4-1.4-.4-1.3 0-2.3.9-2.6 2l10.1 10.3z"
                style={{
                    fill: "#0f9d58",
                }}
            />
            <path
                d="M45.7 10h-2.8v.7H45c-.1.6-.3 1-.7 1.3-.4.3-.9.5-1.4.5-.6 0-1.1-.2-1.6-.6-.4-.4-.6-1-.6-1.6 0-.7.2-1.2.6-1.6.4-.4 1-.6 1.6-.6.3 0 .6.1.9.2.3.1.5.3.7.5L45 8c-.2-.3-.5-.5-.9-.6-.4-.2-.8-.2-1.2-.2-.8 0-1.5.3-2.1.9-.6.5-.8 1.2-.8 2.1 0 .8.3 1.5.9 2.1.6.6 1.3.9 2.1.9.9 0 1.6-.3 2.1-.8s.7-1.1.7-1.9c-.1-.2-.1-.4-.1-.5zm1.1-2.7V13h3.3v-.7h-2.6v-1.8h2.3v-.7h-2.3V8h2.6v-.7h-3.3zm8 .7v-.7h-3.9V8h1.6v5h.7V8h1.6zm3.6-.7h-.7V13h.7V7.3zm4.8.7v-.7h-3.9V8h1.6v5h.7V8h1.6zm7.4 0c-.6-.6-1.3-.9-2.1-.9s-1.5.3-2.1.9c-.6.6-.8 1.3-.8 2.1s.3 1.6.8 2.1c.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9c.6-.6.8-1.3.8-2.1.1-.8-.2-1.5-.8-2.1zm-3.6.5c.4-.4.9-.6 1.6-.6.6 0 1.1.2 1.6.6.4.4.6 1 .6 1.6 0 .7-.2 1.2-.6 1.6-.4.4-.9.6-1.6.6-.6 0-1.1-.2-1.6-.6-.4-.4-.6-1-.6-1.6-.1-.6.1-1.1.6-1.6zm6.2 1V8.4l2.9 4.7h.8V7.3h-.7v4.4l-2.8-4.4h-.9V13h.7V9.5z"
                style={{
                    fill: "#fff",
                    stroke: "#fff",
                    strokeWidth: 0.19,
                    strokeMiterlimit: 10,
                }}
            />
        </g>
    </svg>
)
export default SvgComponent
