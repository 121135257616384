import React from 'react';
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import styled from "styled-components";
import {Button} from "antd";

const HeaderButton = styled((props) => <a {...props}>{props.children}</a>)`
    text-transform: unset;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${Colors.siteBlack};
    padding: 5px 10px;
    border-radius: 5px;
    :hover {
        background: ${Colors.siteBg};
    }
`;

const HeaderButtonActive = styled((props) => <a {...props}>{props.children}</a>)`
    text-transform: unset;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${Colors.siteBlack};
    border-bottom: 2px solid ${Colors.siteBlack};
    background: transparent;
    padding: 5px 10px;
    :hover {
        background: ${Colors.siteBg};
        border-radius: 5px 5px 0 0;
    }
`;

const SmallText = styled.div`
    font-size: 16px;
    font-weight: ${props => props?.weight ?? '400'};
    white-space: pre-wrap;
`

const TinyText = styled.div`
    font-size: 12px;
    font-weight: 400;
`

const Circle = styled((props) => <div {...props}>{props.children}</div>)`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-size: 13px;
    justify-content: center;
    background: ${Colors.weekDayDark};
`;

const DropDownButton = styled.div`
    height: 40px;
    width: ${props => props?.width ?? 'fit-content'};
    padding: 0 10px;
    background: ${Colors.siteBg};
    border: 1px solid ${Colors.siteScheduleBorder};
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    display: flex;
    :hover {
        background: ${Colors.siteScheduleBorder};
    }
`;

const Btn = styled(Button)`
    font-weight: 500;
    width: ${props => props?.width ?? 'fit-content'};
    margin: ${({margin}) => margin ?? 0};
    flex: ${({flex}) => flex ?? 'unset'};
`;

const SeparatorVertical = styled.div`
    width: 1px;
    background: ${({color}) => color ?? Colors.siteScheduleBorder};
    margin: ${({margin}) => margin ?? 0};
`;

const SeparatorVerticalDiv = styled(SeparatorVertical)`
    height: ${({height}) => height ?? '20px'};
`;

const SeparatorHorizontal = styled.div`
    height: 1px;
    background: ${Colors.siteScheduleBorder};
    margin: ${({margin}) => margin ?? 0};
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${({height}) => height ?? 'inherit'};
    position: relative;
`;

const Header = styled.div`
    font-weight: 600;
    font-size: 28px;
    text-transform: capitalize;
    @media (max-width: 900px) {
        font-size: 24px;
    }
`;

const HeaderLine = styled(Header)`
    @media (max-width: 900px) {
        font-size: 20px;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
`;

const SmallHeader = styled.div`
    font-weight: 600;
    font-size: ${({fontSize}) => fontSize ?? '20px'};
`;

const FlexColumnSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${({flex}) => flex ?? 1};
    gap: ${({gap}) => gap};
    height: ${({height}) => height ?? 'auto'};
    max-height: ${({maxHeight}) => maxHeight ?? 'auto'};
    overflow: ${({overflow}) => overflow ?? 'hidden'};
    align-items: ${({align}) => align ?? 'none'};
    padding: ${({padding}) => padding ?? 0};
    justify-content: ${({justify}) => justify ?? 'normal'};
`;

const GreyText = styled.div`
    font-size: ${({fontSize}) => fontSize ?? '14px'};
    color: ${({color}) => color ?? Colors.greyText};
`;

const GreyTextClickable = styled(GreyText)`
    cursor: pointer;
    text-decoration: underline;
    @media (max-width: 900px) {
        text-decoration: underline;
    }
`;

const FlexRowSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${({align}) => align ?? 'center'};
    flex: ${({flex}) => flex ?? 1};
    gap: ${({gap}) => gap};
    justify-content: ${({justify}) => justify ?? 'start'};
    height: ${({height}) => height ?? 'auto'};
`;

const ClickableDiv = styled.div`
    cursor: pointer;
`;

const SmallLogo = styled.img`
    max-width: ${({mobile}) => mobile ? 50 : 150}px;
    max-height: 100%;
    object-fit: contain;
    border-radius: 3px;
    //cursor: pointer;
`;

const BoldCollapseText = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

const ErrorText = styled.span`
    color: ${Colors.siteError};
    font-size: 14px;
`;

const BlackBgHoverOutlineBtn = styled(Btn)`
    :hover {
        background-color: ${Colors.siteBlack};
        color: ${Colors.white} !important;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    flex-direction: ${({dir}) => dir ?? 'column'};
    gap: ${({gap}) => gap ?? '10px'};
    flex: ${({flex}) => flex ?? 'unset'};
    padding: ${({padding}) => padding ?? 0};
    flex: ${({flex}) => flex ?? 'unset'};
    padding: ${({padding}) => padding ?? 0};
`;


const EllipsisText = styled.span`
    width: ${({isMobile, width}) => isMobile ? '100%' : width ?? '100px'};
    max-width: ${({isMobile, width}) => isMobile ? '100%' : width ?? '100px'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
`;

const ArrowWrapper = styled.div`
    cursor: pointer;
    border-radius: 5px;
    height: 26px;
    width: 26px;
    justify-content: center;
    display: flex;
    align-items: center;
    
    :hover {
        background: ${Colors.fullyBooked};
    }
`;

const DisabledCta = styled.div`
    width: ${({width}) => width ?? 'auto'};
    font-weight: 500;
    text-align: center;
    justify-content: center;
    display: flex;
    height: 38px;
    flex-direction: column;
    border: ${({isMobile}) => isMobile ? `1px solid ${Colors.weekDayDark}` : 'unset'};
    border-radius: ${({isMobile}) => isMobile ? '5px' : '0'};
    margin: ${({margin}) => margin ?? 0};
    background-color: ${({bg}) => bg ?? 'auto'};
`;

const FlexSection = styled(FlexRowSection)`
    flex-direction: ${({mobile, dir}) => dir ?? (mobile ? 'column' : 'row')};
    align-items: ${({align,mobile}) => align ?? mobile ? 'start' : 'center'};
    width: ${({mobile, width}) => mobile ? '100%' : width ?? 'auto'};
`;

const GreyBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
    justify-content: center;
    align-items: center;
    background: ${Colors.siteBg};
    border: 1px solid ${Colors.weekDayDark};
    border-radius: 5px;
`

const EmptyFlexDiv = styled.div`
    display: flex; 
    flex-direction: row;
    flex: 1;
`;

const CustomFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    > div {
        min-width: 48%;
    }
    .simple-input-number {
        width: 100%;
        height: 38px;
    }
`;

const CustomFieldContainerSessionInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const CustomFieldItemSessionInfo = styled.div`
    display: flex;
    padding: 5px 0;
    align-items: center;
`;

export {HeaderButton, SmallText, TinyText, Circle, DropDownButton, Btn, SeparatorVertical, Wrapper, Header, FlexColumnSection,
    GreyTextClickable, GreyText, FlexRowSection, ClickableDiv, SeparatorVerticalDiv, SmallLogo,
    BoldCollapseText, BlackBgHoverOutlineBtn, SmallHeader, ErrorText, SeparatorHorizontal, FlexDiv, EllipsisText, DisabledCta,
    FlexSection, GreyBox, HeaderLine, EmptyFlexDiv, HeaderButtonActive, ArrowWrapper, CustomFieldContainer, CustomFieldContainerSessionInfo,
    CustomFieldItemSessionInfo}