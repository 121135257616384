import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {LOADING_PAGE, LOADING_SECTION, SITE_REFERER} from "../constants/constants";
import {apiAction} from "../helpers/HTTP";

export const siteSlice = createSlice({
    name: 'site',
    initialState: {
        box: null,
        identifier: null,
        referrer: SITE_REFERER,
        whitelabel: 'Arbox',
        language: null,
        loadingType: null,
        error: null,
        appLink: null,
        selectedLocation: null,
        reFetch: false,
        isKiosk: false,
        whitelabelProps: null,
    },
    reducers: {
        setBox: (state, action) => {
            state.box = action.payload
        },
        setIdentifier: (state, action) => {
            state.identifier = action.payload
        },
        setReferrer: (state, action) => {
            state.referrer = action.payload
        },
        setWhitelabel: (state, action) => {
            state.whitelabel = action.payload
        },
        setLang: (state, action) => {
            state.language = action.payload
        },
        toggleReFetch: (state, action) => {
            state.reFetch = action.payload
        },
        setLoadingType: (state, action) => {
            state.loadingType = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setIsKiosk: (state, action) => {
            state.isKiosk = action.payload
        },
    },
    extraReducers: builder => {
        // BOX INFO
        builder.addCase(getBoxData.pending, (state,action) => {
            state.loadingType = LOADING_PAGE
        })
        builder.addCase(getBoxData.fulfilled, (state, action) => {
            state.loadingType = null
            state.error = null
            state.box = action.payload.data
            state.selectedLocation = action.payload.location ?? state.selectedLocation ?? action.payload.data.locations_box[0]
        })
        builder.addCase(getBoxData.rejected, (state, action) => {
            state.loadingType = null
            state.box = null
            state.error = action.payload?.message
        })

        // GET APP LINK
        builder.addCase(getAppLink.pending, (state,action) => {
            state.loadingType = LOADING_SECTION
        })
        builder.addCase(getAppLink.fulfilled, (state, action) => {
            state.loadingType = null
            state.error = null
            state.appLink = action.payload
        })
        builder.addCase(getAppLink.rejected, (state, action) => {
            state.loadingType = null
            state.appLink = null
            state.error = action.payload?.error.messageToUser
        })

        // GET APP PROPS
        builder.addCase(getWhitelabelProps.pending, (state,action) => {
            state.loadingType = LOADING_SECTION
        })
        builder.addCase(getWhitelabelProps.fulfilled, (state, action) => {
            state.loadingType = null
            state.error = null
            state.whitelabelProps = action.payload
        })
        builder.addCase(getWhitelabelProps.rejected, (state, action) => {
            state.loadingType = null
            state.whitelabelProps = null
            state.error = action.payload?.error.messageToUser
        })
    }
})

export const { setBox, setIdentifier, setReferrer, setWhitelabel, setLang, toggleReFetch, setLoadingType, setError, setSelectedLocation, setIsKiosk } = siteSlice.actions

export default siteSlice.reducer


export const getBoxData = createAsyncThunk('getBoxData', async (params, {dispatch, rejectWithValue}) => {
    try {
        const res = await apiAction(`site/box/${params.identifier}`, 'get');
        let location = null
        if(params.location) {
            location = res.data.locations_box.find(location => location.id === parseInt(params.location))
        }
        return {data: res.data, location: location};
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const getAppLink = createAsyncThunk('getAppLink', async (params, {rejectWithValue}) => {
    try {
        return await apiAction('site/whitelabelAppLink', 'get');
    } catch (e) {
        return rejectWithValue(e);
    }
})

export const getWhitelabelProps = createAsyncThunk('getWhitelabelProps', async (params, {dispatch, rejectWithValue}) => {
    try {
        const res = await apiAction(`whiteLabel/properties`, 'post');
        return res.data;
    } catch (e) {
        return rejectWithValue(e);
    }
})