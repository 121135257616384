import React from 'react';
import './calendarView.scss'
import dayjs from "dayjs";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {t} from "i18next";
import EmptyCalendarView from "../../../svg/EmptyCalendarView";
import {useDispatch, useSelector} from "react-redux";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";
import SessionBookingInfo from "../../../components/SessionBookingInfo";
import {Tooltip} from "antd";
import {FlexRowSection} from "../../../styles/globalStyles";
import {bookingTypes} from "@arboxappv4/shared/src/helpers/constants";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {isRTL} from "../../../helpers/i18n";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import WaitlistIcon from "../../../svg/WaitlistIcon";

const CalendarView = (props) => {
    const { filteredSessions, isEmpty, selectedLocation, onSessionClicked } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const {isMobile} = useScreenSize()

    const getBadge = (session) => {
        if(session.booking_option === bookingTypes.CANCEL_SCHEDULE_USER || (session.past && session.user_booked)) {
            return (
                <span className={`badge ${!isRTL() && 'right'}`} style={isMobile ? {backgroundColor: Colors.siteBookedBadge} : {}}>
                    {isMobile ? t('booked') : <Tooltip title={t('booked-tooltip')}><FontAwesomeIcon icon={solid('square-check')} color={Colors.siteBookedBadge}/></Tooltip>}
                </span>
            )
        } else if (session.booking_option === bookingTypes.CANCEL_WAIT_LIST) {
            return (
                <span className={`badge ${!isRTL() && 'right'}`} style={isMobile ? {backgroundColor: Colors.siteWaitListBadge} : {}}>
                    {isMobile ? t('waitlist') : <Tooltip title={t('icon-waitlist-tooltip')}><WaitlistIcon/></Tooltip>}
                </span>
            )
        }
    }

    return (
        <div className={'calendar-view-wrapper'}>
            {/* HEADER COLUMNS (DATES) */}
            <div className={'calendar-wrapper'}>
                {filteredSessions && Object.keys(filteredSessions).map((date,i) => {
                    const dateJs = dayjs(date, DB_DATE_FORMAT)
                    return (
                            <div className={'date-header'} key={`date-wrapper-${date}-${i}`}>
                                <div className={`date ${date === dayjs().format(DB_DATE_FORMAT) ? 'selected' : ''}`}>{dateJs.date()}</div>
                                <div className={'day'}>{t(`day-short-${dateJs.day()}`)}</div>
                            </div>
                    )
                })}
            </div>
            {/* CONTENT COLUMNS (SESSIONS) */}
            <div style={{overflow: 'auto'}}>
                <div className={`calendar-wrapper ${isEmpty ? '' : 'sessions-wrapper'}`}>
                    {filteredSessions && Object.values(filteredSessions).map((sessionsByDate,i) => {
                        return (
                            <div className={'date-events-wrapper'} key={`date-events-wrapper-${i}`}>
                                {sessionsByDate.map(session => {
                                    const displayProps = getDisabledPagesProps(session)
                                    return (
                                    <div className={'session-wrapper'} key={`session-wrapper-${session.id}`} onClick={() => onSessionClicked(session)}>
                                        <span className={'normal-text'}>{dayjs(session.time, 'HH:mm').format(globalLocation.time_format)} - {dayjs(session.end_time, 'HH:mm').format(globalLocation.time_format)}</span>
                                        <span className={'bold-text'}>{session.box_categories.name}</span>
                                        {displayProps.showCoach && session.coach && <span className={'normal-text'}>{session.coach.full_name}</span>}
                                        <FlexRowSection gap={'5px'}>
                                            <SessionBookingInfo displayProps={displayProps} restStyle={'font-size: 14px;'}/>
                                            {loggedIn && displayProps.showWaitList && displayProps.inWaitList > 0 && <Tooltip title={t('waitlist-tooltip')}><span className={'normal-text'}>({displayProps.inWaitList})</span></Tooltip>}
                                        </FlexRowSection>
                                        {selectedLocation === 'all' && <span className={'normal-text gray-text'}>{session.locations_box.location} </span>}
                                        {getBadge(session)}
                                    </div>
                                )})}
                                {!isEmpty && sessionsByDate.length === 0 && <span className={'no-events-today'}>{t('no-events-today')}</span>}
                            </div>
                        )
                    })}
                </div>
            </div>

            {isEmpty &&
            <div className={'empty-wrapper'}>
                <EmptyCalendarView/>
                <div className={'empty-text'}>{t('empty-calendar-view')}</div>
            </div>}
        </div>
    );
};

export default CalendarView;