import React, {useEffect, useMemo, useState} from 'react';
import {FlexColumnSection, SmallText} from "../../../../styles/globalStyles";
import {Checkbox, Radio} from "antd";
import useScreenSize from "../../../../helpers/hooks/useScreenSize";
import "../waiver.scss"


const MultiChoiceQuestion = (props) => {
    const {question, onSelect, error, answer} = props;
    const [selectedAnswers, setSelectedAnswers] = useState(answer ?? (question.multi_choice ? [] : null));
    const {isMobile} = useScreenSize()

    const isOneChoice = useMemo(() => !question.multi_choice, [question]);

    const answerSelected = (answer) => {
        let answerRes;
        let checkedOption;
        if(isOneChoice) {
            checkedOption = true;
            answerRes = answer.answer_text
            setSelectedAnswers(answer)
        } else {
            const answerIndex = selectedAnswers.findIndex(a => a.id === answer.id);
            answerRes = [...selectedAnswers]
            let newAnswer = {...answer}
            if(answerIndex === -1) {
                checkedOption = true
                newAnswer.checked = true;
                answerRes.push(newAnswer)
            } else {
                checkedOption = false
                newAnswer.checked = false;
                answerRes.splice(answerIndex, 1)
            }
            setSelectedAnswers(answerRes)
        }
        const newAnswerOptions = question?.answers.reduce((acc, opt) => answer.id === opt.id ? [...acc, {...answer, checked: checkedOption}] : [...acc, opt], [])
        onSelect(answerRes, {answers: newAnswerOptions})
    }

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '5px'} flex={'none'}>
            {
                question?.answers.map(option => {
                    if(isOneChoice) {
                        const isSelected = (selectedAnswers === option.answer_text) || (selectedAnswers?.id === option.id);
                        return (
                            <Radio checked={isSelected} onChange={() => answerSelected(option)} key={`multi-choice-answer-${option.id}-${option.answer_text}`} className={error ? 'radio-error': ''}>
                                <SmallText>{option.answer_text}</SmallText>
                            </Radio>
                        )
                    } else {
                        const isSelected = selectedAnswers.findIndex(a => a.id === option.id) !== -1;
                        return (
                            <Checkbox checked={isSelected} onChange={() => answerSelected(option)} key={`multi-choice-answer-${option.id}-${option.answer_text}`} className={error ? 'checkbox-error': ''}>
                                <SmallText>{option.answer_text}</SmallText>
                            </Checkbox>
                        )
                    }
                })
            }

        </FlexColumnSection>
    )
};

export default MultiChoiceQuestion;
