import React, {useEffect, useRef, useState} from 'react';
import {Btn, ErrorText, FlexColumnSection, FlexRowSection, SmallHeader} from "../../../styles/globalStyles";
import WhatsNextBox from "../../../components/WhatsNextBox";
import {t} from "i18next";
import styled from "styled-components";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import FormQuestion from "./FormQuestion";
import "./waiver.scss"
import {Spin} from "antd";
import {signatureTypes, signatureTypesEnum} from "@arboxappv4/shared/src/helpers/constants";
import SignatureHandler from "./SignatureHandler";
import {updateAsyncData} from "@arboxappv4/shared/src/redux/stepperSlice";
import {stepOptions} from "../../StepOptions";
import {setError} from "@arboxappv4/shared/src/redux/siteSlice";

const Waiver = (props) => {
    const {handleSubmit, values, errors, setFieldValue } = props;
    const box = useSelector(state => state.site.box)
    const asyncData = useSelector(state => state.stepper.asyncData)
    const [loading, setLoading] = useState(false);
    const [signatureType, setSignatureType] = useState(null);
    const [signature, setSignature] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [errorNotified, setErrorNotified] = useState(false);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    useEffect(() => {
        console.log('Waiver errors', errors, values)
        if(submitted && Object.keys(errors).length > 0 && !errorNotified) {
            if(errors.digitalFormInfo?.form && !errors.digitalFormInfo?.form?.signature_data) {
                setErrorNotified(true)
                dispatch(setError(t('waiver-missing-fields')))
            }
        }
    }, [errors, submitted]);

    useEffect(() => {
        getForm();
    }, []);

    useEffect(() => {
        setFieldValue('digitalFormInfo.form.agree', signature?.agree)
        if(signature) {
            if (signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE] || signatureType === signatureTypes[signatureTypesEnum.SIGNATURE_ONLY]) {
                setFieldValue('digitalFormInfo.form.signature_data', signature.signature)
            }
        } else if(!signature && values.digitalFormInfo?.form?.signature_data) {
            setFieldValue('digitalFormInfo.form.signature_data', null)
        }
    }, [signature, signatureType]);

    const getForm = async () => {
        setLoading(true)
        const response = await apiAction(`boxDigitalForm/${box.id}`, 'get', null);
        setLoading(false)
        if(response) {
            setSignatureType(signatureTypes[response.digital_form.signature_options])
            const data = {...asyncData, [stepOptions.WAIVER]: response}
            dispatch(updateAsyncData(data))
        } else {
            setSubmitted(true)
            handleSubmit();
        }
    }

    const onAnswer = (questionIndex, newQuestion) => {
        setFieldValue(`digitalFormInfo.form[${questionIndex}]`, newQuestion)
        setFieldValue(`digitalFormInfo.form.questions[${questionIndex}]`, newQuestion)
    }

    const submit = () => {
        setSubmitted(true)
        handleSubmit()
        if(signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_ONLY] || signatureType === signatureTypes[signatureTypesEnum.CHECKBOX_AND_SIGNATURE]) {
            if((!signature || !signature.agree)) {
                dispatch(setError(t('waiver-missing-signature')))
            }
        }
    }

    return (
        <FlexRowSection justify={'space-between'} gap={'40px'} height={'100%'} align={'start'}>
            <FlexColumnExtended gap={'20px'} isMobile={isMobile} height={'100%'}>
                <Spin spinning={loading} style={{flex: 1}}/>
                <FlexColumnSection gap={'20px'} height={`calc(100% - ${38}px)`} overflow={'hidden auto'} flex={1}>
                    { values.digitalFormInfo?.digital_form?.content && <div className={'waiver-content'} dangerouslySetInnerHTML={{__html: values.digitalFormInfo.digital_form.content}}/>}
                    { values.digitalFormInfo?.form && values.digitalFormInfo.form.questions?.map((question, index) => {
                        const error = submitted && errors.digitalFormInfo?.form && errors.digitalFormInfo.form.questions && errors.digitalFormInfo.form.questions[index] ? (errors.digitalFormInfo.form.questions[index].user_answer ?? errors.digitalFormInfo.form.questions[index].checked_array) : null;
                        return (
                            <FlexColumnSection gap={'5px'} flex={'none'} key={`waiver-question-${index}-${question.question}`}>
                                <SmallHeader fontSize={'18px'}>{question.question}</SmallHeader>
                                <FormQuestion question={question} onSelect={(val) => onAnswer(index, val)} error={error}/>
                                {error && <ErrorText>{error ?? t('mandatory-field')}</ErrorText>}
                            </FlexColumnSection>
                        )}
                    )}
                    { values.digitalFormInfo?.digital_form &&
                    <SignatureHandler signatureType={signatureType}
                                      digitalForm={values.digitalFormInfo.digital_form}
                                      onSave={setSignature}
                                      error={errors.digitalFormInfo?.form?.signature_data}
                                      showCheckboxError={!!errors.digitalFormInfo?.form?.agree}
                                      agreeCheckbox={values.digitalFormInfo.form.agree}
                                      setAgreeCheckbox={(val) => setFieldValue('digitalFormInfo.form.agree', val)}
                    />}
                </FlexColumnSection>
                <FlexRowSection height={'38px'} flex={'none'} justify={'end'}>
                    <Btn type={'primary'} onClick={submit} width={isMobile ? '100%' : null}>{t('continue')}</Btn>
                </FlexRowSection>
            </FlexColumnExtended>
            {!isMobile && <WhatsNextBox text={t('register-waiver-whats-next')}/>}
        </FlexRowSection>
    );
};

export default Waiver;

const FlexColumnExtended = styled(FlexColumnSection)`
    padding: ${({isMobile}) => isMobile ? '0 16px' : '20px'};
    background: ${({isMobile}) => isMobile ? 'none' : Colors.white};
    box-sizing: border-box;
    border-radius: 5px;
`;