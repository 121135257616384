import {createSlice} from '@reduxjs/toolkit';

export const LOGIN_MODAL = 'loginModal'
export const MOBILE_MENU_DRAWER = 'mobileMenuDrawer'
export const FORGOT_PASSWORD_MODAL = 'forgotPasswordModal'
export const SELECT_DATE_MODAL = 'selectDateModal'
export const ONE_TIME_PASSWORD_MODAL = 'oneTimePasswordModal'
export const CONFIRMATION_MODAL = 'confirmationModal'
export const SESSION_MEMBERSHIP_MODAL = 'sessionMembershipModal'
export const SHOP_ITEM_MODAL = 'shopItemModal'

const initValues = {
    isOpen: false,
    props: null,
    processRes: null,
    hide: false,
    onClose: null
}

const modalManagerSlice = createSlice({
    name: "modalManager",
    initialState: {
        [LOGIN_MODAL]: {...initValues},
        [MOBILE_MENU_DRAWER]: {...initValues},
        [FORGOT_PASSWORD_MODAL]: {...initValues},
        [SELECT_DATE_MODAL]: {...initValues},
        [ONE_TIME_PASSWORD_MODAL]: {...initValues},
        [CONFIRMATION_MODAL]: {...initValues},
        [SESSION_MEMBERSHIP_MODAL]: {...initValues},
        [SHOP_ITEM_MODAL]: {...initValues},
    },
    reducers: {
        openModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].isOpen = true
                state[modalName].props = data.payload.props
                state[modalName].processRes = null
                state[modalName].onClose = data.payload.onClose ?? null
            }
        },
        closeModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].isOpen = false
                state[modalName].props = null
            }
        },
        updateModalRes: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].processRes = data.payload.res
            }
        },
        hideModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].hide = data.payload.hide
            }
        }
    },
});

export const {openModal, closeModal, updateModalRes, hideModal} = modalManagerSlice.actions;

export default modalManagerSlice.reducer;