import React from 'react';
import FormInput from "./FormInput";
import {customFieldTypes} from "@arboxappv4/shared/src/helpers/constants";
import {CustomFieldContainer, SmallText} from "../../styles/globalStyles";
import dayjs from "dayjs";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import CustomDatePicker from "./CustomDatePicker";
import CustomSelect from "./CustomSelect";
import {t} from "i18next";
import {isMobile} from "../../../../shared/src/helpers/functions";

const CustomFieldSwitcher = (props) => {
    const { data, setFieldValue, touched, errors, isChild, values } = props;

    const updateCustomField = (field, value, index) => {
        setFieldValue(isChild ? `child.customFields[${index}]` : `customFields[${index}]`, {...field, value: value ?? null});
    }

    const getError = (index) => {
        return  isChild ?
                (touched.child?.customFields && errors.child?.customFields && errors.child?.customFields[index] ? errors.child.customFields[index].value : null)
                :
                touched.customFields && errors.customFields && errors.customFields[index] ? errors.customFields[index].value : null;
    }

    return (
        <CustomFieldContainer>
            {data?.map((field, index) => {
                switch (field.custom_field_type) {
                    case customFieldTypes.TEXT:
                    case customFieldTypes.PHONE:
                    case customFieldTypes.NUMBER:
                        return <FormInput label={`${field.custom_field_name}${field.props.isRequired ? '*' : ''}`}
                                          value={field.value}
                                          onChangeCallback={(value) => updateCustomField(field, value, index)}
                                          id={`custom_field_${field.id}`}
                                          type={field.custom_field_type === customFieldTypes.PHONE && 'phone'}
                                          autoComplete={field.custom_field_type === customFieldTypes.PHONE && 'phone'}
                                          inputMode={(field.custom_field_type === customFieldTypes.PHONE && 'tel') || (field.custom_field_type === customFieldTypes.NUMBER && 'tel')}
                                          isNumericOnly={field.custom_field_type === customFieldTypes.NUMBER}
                                          flex={1}
                                          key={`custom_field-${field.id}`}
                                          error={getError(index)}
                                          showOptional={!field.props?.isRequired}
                        />
                    case customFieldTypes.DATE:
                        return  <FormInput label={`${field.custom_field_name}${field.props.isRequired ? '*' : ''}`} value={field.value} error={getError(index)} flex={1} showOptional={!field.props.isRequired} key={`custom_field-${field.id}`}>
                                    <CustomDatePicker className={`custom-date ${getError(index) && 'custom-date-error'}`} onDateSelect={(value) => updateCustomField(field, value, index)} defaultDate={field.value} />
                                </FormInput>
                    case customFieldTypes.SELECT:
                        return  <FormInput label={`${field.custom_field_name}${field.props.isRequired ? '*' : ''}`} value={field.value} error={getError(index)} flex={1} showOptional={!field.props?.isRequired} key={`custom_field-${field.id}`}>
                                    <CustomSelect className={`custom-select ${getError(index) && 'custom-select-error'}`} allowClear={!field.props?.isRequired} onChange={(value) => updateCustomField(field, value, index)} options={field.props.options} defaultValue={field.value} placeholder={t('select')} />
                                </FormInput>
                    case customFieldTypes.BOOLEAN:
                        return  <FormInput label={`${field.custom_field_name}${field.props.isRequired ? '*' : ''}`} value={field.value} error={getError(index)} flex={1} showOptional={!field.props?.isRequired} key={`custom_field-${field.id}`}>
                                    <CustomSelect className={`custom-select ${getError(index) && 'custom-select-error'}`} allowClear={!field.props?.isRequired} onClear={() => updateCustomField(field, null, index)} onChange={(value) => updateCustomField(field, value, index)} options={[{label: t('yes'), value: 1},{label: t('no'), value: 0}]} defaultValue={field.value} placeholder={t('select')}/>
                                </FormInput>
                }
            })}
        </CustomFieldContainer>
    )

};

export default CustomFieldSwitcher;
