import React, {useEffect, useState} from 'react';
import YesNoQuestion from "./questions/YesNoQuestion";
import MultiChoiceQuestion from "./questions/MultiChoiceQuestion";
import OpenQuestion from "./questions/OpenQuestion";
import {questionTypes} from "@arboxappv4/shared/src/helpers/constants";

const FormQuestion = (props) => {
    const {question, onSelect, error} = props;
    const [questionComponent, setQuestionComponent] = useState(null);

    useEffect(() => {
        setQuestionComponent(questionTypeHandler())
    }, [question, error]);

    const handleAnswer = (answer, questionOverride = {}) => {
        let questionNew = {...question, ...questionOverride}
        if(question.type === questionTypes.MULTI_CHOICE) {
            if(question.multi_choice) {
                questionNew.checked_array = answer
            } else {
                questionNew.user_answer = answer
            }
        } else {
            questionNew.user_answer = answer
        }
        onSelect(questionNew)
    }


    const questionTypeHandler = () => {
        switch (question.type) {
            case 'questionYesNo':
            case 'questionYesNoAdv':
                return <YesNoQuestion onSelect={handleAnswer} error={error} answer={question.user_answer}/>;
            case 'questionOpen':
                return <OpenQuestion onSelect={handleAnswer} error={error} answer={question.user_answer}/>;
            case 'questionMultipleChoice':
                return <MultiChoiceQuestion onSelect={handleAnswer} question={question} error={error} answer={question.user_answer ?? question.checked_array}/>;
            default:
                return null;
        }
    }

    return (
        <>
            {questionComponent}
        </>
    );
}

export default FormQuestion;