import React, {useMemo} from 'react';
import { Card, Image } from 'antd';
import {isRTL} from "../../../helpers/i18n";
import {FlexColumnSection} from "../../../styles/globalStyles";
import {getMembershipPriceText} from "../../../helpers/functions";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {useSelector, useDispatch} from "react-redux";
import {openModal, SHOP_ITEM_MODAL} from "@arboxappv4/shared/src/redux/modalManagerSlice";

const ShopItem = (props) => {
    const { item, onItemSelected } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    const onItemClick = () => {
        dispatch(openModal({modalName: SHOP_ITEM_MODAL, props: {item, onPurchase: onItemSelected}}))
    }

    return (
        <Card className={'shop-item-card'} hoverable onClick={onItemClick}>
            <div className={'shop-item-info'}  style={{maxWidth: item.image ? `calc(100% - ${isMobile ? 100 : 144}px)` : '100%'}}>
                <FlexColumnSection gap={'10px'}>
                    <div className={'item-name'}>{item.name}</div>
                    <div className={'description'}>{item.description}</div>
                </FlexColumnSection>
                <span>{getMembershipPriceText(item, currencySymbol)}</span>
            </div>
            {item.image &&
                <div className={`shop-item-image ${isRTL() ? 'radius-left' : 'radius-right'}`}>
                    <Image width={isMobile ? 100 : 144} height={isMobile ? 100 : 144} src={item.image} preview={false}/>
                </div>
            }
        </Card>
    );
};

export default ShopItem;