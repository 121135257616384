import React from 'react';
import styled from "styled-components";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FlexColumnSection, SmallHeader} from "../../../styles/globalStyles";
import UserAvatar from "../../../components/UserAvatar";
import {t} from "i18next";
import CustomCollapse from "../../../components/UI/CustomCollapse";

const SlotsDisplay = (props) => {
    const { slots, isSmall, isCoachAppointment, onSlotSelect } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const {isMobile} = useScreenSize()

    if(isSmall) {
        return (
            <GridLayout>
                {slots.map((slot, i) => <SmallSlot isMobile={isMobile} onClick={() => onSlotSelect(slot)} key={`appointment-slots-${i}`}>{dayjs(slot.time, 'HH:mm').format(globalLocation.time_format)}</SmallSlot>)}
            </GridLayout>
        )
    }

    return (
        <FlexColumnSection gap={'10px'} overflow={'hidden auto'}>
            {slots.map((slot, i) => (
                <React.Fragment key={`appointment-slots-${i}`}>
                    {isMobile ?
                        <MobileBigSlot onClick={() => onSlotSelect(slot)}>
                            <div style={{width: '50px'}}><SmallHeader fontSize={'16px'}>{dayjs(slot.time, 'HH:mm').format(globalLocation.time_format)}</SmallHeader></div>
                            {isCoachAppointment ? <UserAvatar user={slot.coach} nameType={'full'}/> : <div>{slot.space.name}</div>}
                        </MobileBigSlot>
                        :
                        <CustomCollapse headerText={dayjs(slot.time, 'HH:mm').format(globalLocation.time_format)}
                                        disableExpand={'icon'}
                                        ctaText={t('book')}
                                        headerWidth={'85px'}
                                        headerInfo={isCoachAppointment ? <UserAvatar user={slot.coach} nameType={'full'}/> : <div>{slot.space.name}</div>}
                                        onBtnClick={() => onSlotSelect(slot)}
                        />
                    }
                </React.Fragment>
            ))}
        </FlexColumnSection>
    );
};

export default SlotsDisplay;

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
`;

const SmallSlot = styled.div`
    border-radius: 5px;
    border: 1px solid ${Colors.siteBlack};
    justify-content: ${({isMobile}) => isMobile ? 'center' : 'start'};
    height: 42px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${Colors.white};
    :hover {
        background: ${Colors.fullyBooked};
    }
`;

const MobileBigSlot = styled.div`
    border-radius: 5px;
    border: 1px solid ${Colors.siteBlack};
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: ${Colors.white};
`;