export const getDisabledPagesProps = (data) => {
    const hideNumOpenSlots = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'slotsOpen');
    const hideNumRegisteredUsers = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'arrivingAmount');
    const hideCoachDisplay = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'coach');
    const hideWhoIsBooked = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'whoIsBooked');
    const showStandBy = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'standBy');
    const hideSpaceName = data.disable_pages_app.some(item => item.area === 'schedule' && item.section_name === 'space');
    const slots = data.max_users - data.registered;

    return {
        showCoach: !hideCoachDisplay,
        showWaitList: showStandBy,
        showBookedUsers: !hideWhoIsBooked,
        showNumOpenSpots: !hideNumOpenSlots,
        showNumRegistered: !hideNumRegisteredUsers,
        showSpaceName: !hideSpaceName,
        openSpots: slots,
        registered: data.registered,
        maxUsers: data.max_users,
        inWaitList: data.stand_by,
        positionInWaitList: data.stand_by_position
    };
}