import React, {useEffect, useState} from 'react';
import {
    LOGIN_MODAL,
    openModal,
    SELECT_DATE_MODAL,
    updateModalRes
} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {useDispatch, useSelector} from "react-redux";

const useMembershipStartDate = (props) => {
    const { values, setFieldValue, handleSubmit, blockOnLoggedOut } = props;
    const selectDateRes = useSelector(state => state.modalManager[SELECT_DATE_MODAL].processRes)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const flags = useSelector(state => state.stepper.flags)
    const dispatch = useDispatch()

    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [fromLoginPopup, setFromLoginPopup] = useState(false);

    useEffect(() => {
        if(selectDateRes) {
            setFieldValue('cart', {...values.cart, start: selectDateRes})
            setReadyToSubmit(true)
            dispatch(updateModalRes({modalName: SELECT_DATE_MODAL, res: null}))
        }
    }, [selectDateRes]);

    useEffect(() => {
        if(values.cart && readyToSubmit) {
            if(loggedIn || !blockOnLoggedOut) {
                handleSubmit(values)
                setReadyToSubmit(false)
            } else {
                setFromLoginPopup(true)
                dispatch(openModal({modalName: LOGIN_MODAL, props: {membership: values.cart}}))
            }
        }
    }, [values.cart, readyToSubmit]);

    useEffect(() => {
        if(loggedIn && fromLoginPopup && values.cart) {
            setFromLoginPopup(false)
            handleSubmit(values)
        }
    }, [loggedIn, fromLoginPopup]);

    useEffect(() => {
        console.log('useMembershipStartDate values', values)
    }, [values]);

    useEffect(() => {
        //submit only after we know for sure that the new flow has been initialized (that is why flags.continueWithoutMembership=false)
        if(values.continueWithoutMembership && !flags.continueWithoutMembership) {
            handleSubmit(values)
        }
    }, [values.continueWithoutMembership, flags.continueWithoutMembership]);


};

export default useMembershipStartDate;