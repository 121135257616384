import React, {useEffect, useMemo, useState} from 'react';
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {useDispatch, useSelector} from "react-redux";
import orderBy from "lodash-es/orderBy";
import groupBy from 'lodash-es/groupBy';
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {
    EllipsisText,
    FlexColumnSection,
    FlexSection, SeparatorVerticalDiv,
    SmallHeader,
    SmallText
} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {Spin} from "antd";
import {t} from "i18next";
import {
    addFiltersToQueryParams,
    getCourseInfoArray,
    getMembershipPriceText,
    sortCourseEventDays
} from "../../../helpers/functions";
import CustomCollapse from "../../../components/UI/CustomCollapse";
import dayjs from "dayjs";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {updateFlags} from "@arboxappv4/shared/src/redux/stepperSlice";
import SingleItemPage from "../SingleItemPage";
import CoursesHeader from "./CoursesHeader";

const Courses = (props) => {
    const { handleSubmit, values, setFieldValue, isDirectCourse } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const user = useSelector(state => state.user.data)
    const flags = useSelector(state => state.stepper.flags)

    const [courses, setCourses] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [filteredCourses, setFilteredCourses] = useState(null);

    const currencySymbol = useMemo(() => globalLocation?.currency_symbol, [globalLocation]);
    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()

    useEffect(() => {
        if(globalLocation) {
            setSelectedLocation(globalLocation?.id)
        }
    }, [globalLocation]);

    useEffect(() => {
        if(selectedLocation) {
            dispatch(updateFlags({serviceReady: false, courseInfo: null}))
            fetchCourses()
            if (selectedLocation !== 'all') addFiltersToQueryParams({location: selectedLocation})
        }
    }, [selectedLocation, user]);

    useEffect(() => {
        if(values.serviceId && flags.serviceReady) {
            dispatch(updateFlags({serviceReady: false}))
            handleSubmit()
        }
    }, [values.serviceId, flags.serviceReady]);

    useEffect(() => {
        if(filteredCourses) {
            const empty = Object.values(filteredCourses).every((courses) => courses.length === 0)
            setIsEmpty(empty)
        }
    }, [filteredCourses]);

    const fetchCourses = async () => {
        setFetching(true)
        setFilteredCourses(null)
        const coursesRes = await apiAction(`getHugim`, 'post', {location_id: selectedLocation === 'all' ? null : selectedLocation, user_id: user ? user.id : null});
        handleCoursesResult(coursesRes)
        setFetching(false)
    }

    const handleCoursesResult = (coursesRes) => {
        setIsEmpty(coursesRes.length === 0)
        let sorted = sortAlphabetically(coursesRes, 'series_name');
        sorted = orderBy(sorted, ['start_date']);
        const groupedByType = groupBy(sorted, course => course.box_categories.name);
        setCourses(groupedByType)
        setFilteredCourses(groupedByType)
    }

    const getCourseHeaderInfo = (course) => {
        let days = sortCourseEventDays(course.series_events).map(day => t(`day-short-${day.day_of_week}`))

        return (
            <div style={{width: isMobile ? '100%' : 'auto', display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '8px' : '20px',  alignItems: isMobile ? 'start' : 'center'}}>
                <EllipsisText isMobile={isMobile} width={'200px'}>{getMembershipPriceText(course.membership_types[0], currencySymbol)}</EllipsisText>
                <EllipsisText isMobile={isMobile} width={'250px'}>{days.length === 1 ? t('in-day') : t('in-days')} {days.join(', ')}</EllipsisText>
                {selectedLocation === 'all' && <EllipsisText isMobile={isMobile} width={loggedIn ? '50%' : '100px'}>{course.locations_box.location}</EllipsisText>}
            </div>
        )
    }

    const getCollapseInfo = (course) => {
        const info = getCourseInfoArray(course)
        const days = sortCourseEventDays(course.series_events)
        const hasCustomFields = info.customFields?.props?.children?.length > 0;

        return (
            <FlexColumnSection gap={isMobile ? '8px' : '15px'}>
                <FlexSection gap={isMobile ? '8px' : '15px'} flex={'unset'} mobile={isMobile} align={'start'}>
                    {(course.box_categories.bio || course.live_link || course.coach || hasCustomFields) &&
                    <FlexColumnSection>
                        {info.coach &&
                        <div>
                            <SmallText weight={'bold'}>{t('staff')}:</SmallText>
                            <FlexColumnSection gap={'0.5rem'}>
                                {info.coach}
                                {info.second_coach}
                            </FlexColumnSection>
                        </div>
                        }
                        { hasCustomFields && <div style={{ paddingTop: '10px' }}>{info.customFields}</div> }
                        {
                            course.box_categories.bio &&
                            <div style={{ paddingTop: '10px' }}>
                                <SmallText weight={'bold'}>{t('description')}:</SmallText>
                                <div style={{flex: 1, whiteSpace: 'pre-wrap'}}>{course.box_categories.bio}</div>
                            </div>
                        }
                        {course.live_link && <div style={{ paddingTop: '10px' }}>{t('online-course')}</div>}
                    </FlexColumnSection>}
                    {(course.box_categories.bio || course.live_link || course.coach || hasCustomFields) && <SeparatorVerticalDiv color={Colors.greyText} height={'100%'} margin={'0 10px'}/>}
                    <div style={{width: '320px'}}>
                        <FlexColumnSection flex={'unset'}>
                            <SmallText weight={'bold'}>{t('course-days-header')}</SmallText>
                            <FlexColumnSection flex={'unset'}>
                                <div>{t('starts-at', {start: dayjs(course.start_date, 'YYYY-MM-DD').format(globalLocation.date_format), interpolation: {'escapeValue': false}})}</div>
                                {course.end_date && <div>{t('ends-at', {end: dayjs(course.end_date, 'YYYY-MM-DD').format(globalLocation.date_format), interpolation: {'escapeValue': false}})}</div>}
                            </FlexColumnSection>
                            {days.map(day => <div key={`key-day-${day.day_of_week}`}>{t(`day-${day.day_of_week}`)}: {dayjs(day.start, 'HH:mm:ss').format(globalLocation.time_format)} - {dayjs(day.end, 'HH:mm:ss').format(globalLocation.time_format)}</div>)}
                            {(course.allow_mid_booking && course.has_started) ? <div>{t('hug-started-expand-label')}</div> : null}
                        </FlexColumnSection>
                    </div>
                    <SeparatorVerticalDiv color={Colors.greyText} height={'100%'} margin={'0 10px'}/>
                    <FlexColumnSection>
                        <SmallText weight={'bold'}>{t('price')}:</SmallText>
                        <div>{getMembershipPriceText(course.membership_types[0], currencySymbol)}</div>
                        {course.membership_types[0].max_installments > 1 && <div>{t('max-installments',{max_installments: course.membership_types[0].max_installments})}</div>}
                        {course.register_group_member ? t('register-group-member-course-label') : null}
                    </FlexColumnSection>
                </FlexSection>
            </FlexColumnSection>
        )
    }

    const onCourseClick = (course) => {
        setFieldValue('course', course) // being used only in direct page but there is always a validation, so we need to insert this to values
        const paymentMembership = course.membership_types[0]
        if(paymentMembership) {
            setFieldValue('serviceId', paymentMembership.id)
            if(!loggedIn) setFieldValue('locations_box_fk', course.locations_box_fk)
            dispatch(updateFlags({serviceReady: true, courseInfo: course, isChildRegistration: !!course.register_group_member}))
        }
    }

    const onFilterChange = (filteredCourses, filtersConfig) => {
        if(filtersConfig?.locations) setSelectedLocation(filtersConfig?.locations.selected ?? null)
        setFilteredCourses(filteredCourses)
    }

    return (
        <>
            {isDirectCourse ?
                <SingleItemPage values={values} setFieldValue={setFieldValue} handleSubmit={handleSubmit} params={{...values, onCtaClick: () => onCourseClick(values.course)}}/>
                :
                <FlexColumnSection gap={'15px'} padding={isMobile ? '0 20px' : 0}>
                    <CoursesHeader courses={courses}
                                   onFiltered={onFilterChange}
                                   selectedLocation={selectedLocation}
                    />
                    <Spin spinning={fetching}/>
                    <FlexColumnSection gap={'20px'} overflow={'hidden auto'}>
                        {isEmpty && <span>{t('courses-empty-state')}</span>}
                        {filteredCourses && Object.keys(filteredCourses).map(coursesType => (
                            <React.Fragment key={`coursesType-${coursesType}`}>
                                {filteredCourses[coursesType].length > 0 &&
                                <FlexColumnSection gap={'5px'} overflow={'unset'} flex={'unset'}>
                                    <SmallHeader>{coursesType}</SmallHeader>
                                    <FlexColumnSection gap={'10px'} overflow={'unset'} flex={'unset'}>
                                        {filteredCourses[coursesType].map(course => (
                                            <CustomCollapse headerText={course.series_name}
                                                            ctaText={t(course.isBookedUser ? 'user-booked-to-course' : course.isFull ? 'full-course' : 'book')}
                                                            headerWidth={'40%'}
                                                            headerInfo={getCourseHeaderInfo(course)}
                                                            onBtnClick={() => onCourseClick(course)}
                                                            key={`course-panel-${course.id}`}
                                                            restStyles={isMobile ? '' : 'width: 100px;'}
                                                            disableExpand={course.isFull || course.isBookedUser ? 'disabled' : null}
                                                            ctaWidth={course.isFull || course.isBookedUser ? 'auto' : '100px'}
                                            >
                                                {getCollapseInfo(course)}
                                            </CustomCollapse>
                                        ))}
                                    </FlexColumnSection>
                                </FlexColumnSection>}
                            </React.Fragment>
                        ))}
                    </FlexColumnSection>
                </FlexColumnSection>
            }
        </>
    );
};

export default Courses;
