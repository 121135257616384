import React from 'react';
import {Spin} from "antd";

const SuspenseLoader = (props) => {
    const { } = props;

    return (
        <div style={{height: '100%', width: '100%', textAlign: 'center', justifyContent: 'center', paddingTop: '250px'}}><Spin size={'large'} tip={'Just a few seconds...'}><div></div></Spin></div>
    );
};

export default SuspenseLoader;