import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 150 150",
        }}
        viewBox="0 0 150 150"
        {...props}
    >
        <style>
            {
                ".st2{fill:none;stroke:#212121;stroke-width:.7735;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st3{fill:#f1f1f1}.st5{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
            }
        </style>
        <circle
            cx={75}
            cy={75}
            r={72.5}
            style={{
                fill: "#e0e0e0",
                stroke: "#fff",
                strokeWidth: 5,
            }}
        />
        <path
            d="M103.3 120H46.7c-2 0-3.7-1.6-3.7-3.6V34.6c0-2 1.6-3.6 3.7-3.6h47.5L107 43.7v72.7c0 2-1.6 3.6-3.7 3.6z"
            style={{
                fill: "#fff",
            }}
        />
        <path
            d="M103.3 120H46.7c-2 0-3.7-1.6-3.7-3.6V34.6c0-2 1.6-3.6 3.7-3.6h47.5L107 43.7v72.7c0 2-1.6 3.6-3.7 3.6z"
            className="st2"
        />
        <path d="M94 31v13h13" className="st3" />
        <path
            d="M94 31v13h13"
            style={{
                fill: "none",
                stroke: "#212121",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <path
            d="M63 50h34M63 55h16M63 67h31M63 72h17M63 83h34M63 88h19M63 99h23M63 104h12"
            className="st2"
        />
        <path
            d="M50 49h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-5c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1zM50 66h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-5c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1zM50 82h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-5c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1zM50 98h5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-5c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1z"
            className="st3"
        />
        <path
            d="M103.3 120H46.7c-2 0-3.7-1.6-3.7-3.6V34.6c0-2 1.6-3.6 3.7-3.6h47.5L107 43.7v72.7c0 2-1.6 3.6-3.7 3.6z"
            className="st2"
            shapeRendering="crispEdges"
        />
        <path
            d="m57 100-3.3 3-1.7-1.5M57 84l-3.3 3-1.7-1.5M57 68l-3.3 3-1.7-1.5M57 51l-3.3 3-1.7-1.5"
            className="st5"
        />
        <path
            d="M107 113c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11z"
            style={{
                fill: "#212121",
            }}
        />
        <path
            d="m104 102 2.7 2 3.3-4"
            style={{
                fill: "none",
                stroke: "#fff",
                strokeWidth: 1.547,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)
export default SvgComponent
