import React from 'react';
import FlowManager from "../../flowManager/FlowManager";
import {flowOptions} from "../../flowManager/FlowOptions";

const WaiverFlow = (props) => {
    const { } = props;

    return (
        <div style={{width: '70%', padding: '20px 0', height: 'calc(100% - 40px)'}}>
            <FlowManager flowSelection={flowOptions.WAIVER}/>
        </div>
    );
};

export default WaiverFlow;