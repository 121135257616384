import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiAction} from "../helpers/HTTP";
import {updateRefreshToken} from "./authSlice";
import {setLoadingType} from "./siteSlice";
import {LOADING_PAGE} from "../constants/constants";

const initialState = {
    isLoading: false,
    isUpdatingProfile: false,
    data: null,
    error: null,
    selectedBoxId: null,
    activeUsersBoxes: null,
    memberships: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setActiveUsersBoxes: (state, action) => {
            state.activeUsersBoxes = {...action.payload}
        },
        resetUser: (state) => initialState
    },
    extraReducers: builder => {
        // USER INFO
        builder.addCase(getUserInfo.pending, (state,action) => {
            state.isLoading = true
        })
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload.data
        })
        builder.addCase(getUserInfo.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload
        })

        // USER'S MEMBERSHIPS BY BOX
        builder.addCase(fetchUserMembershipsByBox.pending, (state,action) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserMembershipsByBox.fulfilled, (state, action) => {
            state.isLoading = false
            state.memberships = action.payload.data
        })
        builder.addCase(fetchUserMembershipsByBox.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload
        })
    }
})

export const { setActiveUsersBoxes, resetUser } = userSlice.actions

export default userSlice.reducer

export const getUserInfo = createAsyncThunk('getUserInfo', async (params, {rejectWithValue, dispatch}) => {
    try {
        dispatch(setLoadingType(LOADING_PAGE))
        const response = await apiAction(`user/profile`, 'get', null);
        dispatch(updateRefreshToken(response.data.refreshToken))
        dispatch(setLoadingType(null))
        return response
    } catch (e) {
        dispatch(setLoadingType(null))
        return rejectWithValue(e);
    }
})

export const fetchUserMembershipsByBox = createAsyncThunk('fetchUserMembershipsByBox', async (params, {rejectWithValue, dispatch}) => {
    try {
        const route = params.active ? `boxes/${params.boxId}/memberships/${params.active}` : `boxes/${params.boxId}/memberships/`;
        return await apiAction(route, 'get', null);
    } catch (e) {
        return rejectWithValue(e);
    }
})