import * as React from 'react';
import {useEffect, useState} from "react";
import {FlexColumnSection, SmallText} from "../../../../styles/globalStyles";
import {Radio} from "antd";
import {t} from "i18next";
import useScreenSize from "../../../../helpers/hooks/useScreenSize";
import "../waiver.scss"

const YesNoQuestion = (props) => {
    const {onSelect, error, answer} = props;
    const [selectedAnswer, setSelectedAnswer] = useState(answer ?? null);
    const {isMobile} = useScreenSize()

    const answerSelected = (answer) => {
        setSelectedAnswer(answer)
        onSelect(answer)
    }

    return (
        <FlexColumnSection gap={isMobile ? '10px' : '5px'} flex={'none'}>
            <Radio checked={selectedAnswer === "true"} onChange={() => answerSelected("true")} className={error ? 'radio-error': ''}>
                <SmallText>{t('yes')}</SmallText>
            </Radio>
            <Radio checked={selectedAnswer === "false"} onChange={() => answerSelected("false")} className={error ? 'radio-error': ''}>
                <SmallText>{t('no')}</SmallText>
            </Radio>
        </FlexColumnSection>
    );
};

export default YesNoQuestion;
