import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={57}
        height={63}
        fill="none"
        {...props}
    >
        <g filter="url(#a)">
            <path
                fill="#fff"
                stroke="#212121"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={22.926}
                d="M3.455 7.09h49.09C53.895 7.09 55 8.186 55 9.527v48.981c0 1.34-1.105 2.437-2.455 2.437H3.455C2.105 60.945 1 59.85 1 58.508V9.527C1 8.187 2.105 7.09 3.455 7.09Z"
            />
        </g>
        <path
            fill="#F1F1F1"
            fillRule="evenodd"
            d="M6.9 54.853h41.727V22.93H6.899v31.923Z"
            clipRule="evenodd"
        />
        <path
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={22.926}
            d="M13.272 11.965c-1.687 0-3.068-1.37-3.068-3.046V4.045c0-1.675 1.38-3.046 3.068-3.046 1.688 0 3.068 1.37 3.068 3.046v2.437M28 11.965c-1.688 0-3.068-1.37-3.068-3.046V4.045c0-1.675 1.38-3.046 3.068-3.046 1.687 0 3.068 1.37 3.068 3.046v2.437M42.727 11.965c-1.687 0-3.068-1.37-3.068-3.046V4.045c0-1.675 1.38-3.046 3.068-3.046 1.688 0 3.069 1.37 3.069 3.046v2.437M1.614 16.838h52.773"
        />
        <defs>
            <filter
                id="a"
                width={56}
                height={55.855}
                x={0.5}
                y={6.59}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx={1} dy={1} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4791_9168"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4791_9168"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
export default SvgComponent
