import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
            enableBackground: "new 0 0 150 150",
        }}
        viewBox="0 0 150 150"
        {...props}
    >
        <style>
            {
                ".st3{fill:#fff}.st4{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
            }
        </style>
        <circle
            cx={75}
            cy={75}
            r={72.5}
            style={{
                fill: "#ecfaf1",
                stroke: "#fff",
                strokeWidth: 5,
            }}
        />
        <path
            d="M39 36.4V124h63v-9.8l.2.4h9.2V27h-63v9.3l.1.2H39z"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#fff",
            }}
        />
        <path
            d="M39 36.4h-.5c0-.3.2-.5.5-.5v.5zm0 87.7v.5c-.3 0-.5-.2-.5-.5h.5zm63 0h.5c0 .3-.2.5-.5.5v-.5zm0-9.8h-.5c0-.2.1-.4.4-.5.2-.1.4 0 .6.2l-.5.3zm.3.3v.5c-.2 0-.3-.1-.4-.2l.4-.3zm9.1 0h.5c0 .3-.2.5-.5.5v-.5zm0-87.6v-.5c.3 0 .5.2.5.5h-.5zm-63 0h-.5c0-.3.2-.5.5-.5v.5zm0 9.3-.4.3c-.1-.1-.1-.2-.1-.3h.5zm.1.1.4-.3c.1.2.1.4 0 .5-.1.2-.3.3-.4.3v-.5zm-10 60.4V36.4h1v60.4h-1zm0 16.8V96.8h1v16.7h-1zm0 10.5v-10.5h1v10.5h-1zm63.5.5H39v-1h63v1zm.5-10.3v9.8h-1v-9.8h1zm-.6.6-.2-.4.8-.6.2.4-.8.6zm9.5.2h-9.2v-1h9.2v1zm.5-88.1v87.6h-1V27h1zm-63.5-.5h63.1v1H48.4v-1zm-.5 9.8V27h1v9.3h-1zm.2.4-.1-.2.8-.6.1.2-.8.6zm-9.1-.8h9.5v1H39v-1z"
            style={{
                fill: "#212121",
            }}
        />
        <path d="M48.3 36.3V27h63.1v87.6h-9.2" className="st3" />
        <path d="M48.3 36.3V27h63.1v87.6h-9.2" className="st4" />
        <path d="M38.9 96.8V36.4H102V124H38.9v-10.5" className="st3" />
        <path d="M38.9 96.8V36.4H102V124H38.9v-10.5" className="st4" />
        <path
            d="M91.8 65.9c0 11.8-9.6 21.4-21.4 21.4-11.8 0-21.4-9.6-21.4-21.4s9.6-21.4 21.4-21.4c11.9 0 21.4 9.6 21.4 21.4z"
            style={{
                fill: "#f1f1f1",
            }}
        />
        <path
            d="M79 74.7H62v-.1c0-4.7 3.8-8.5 8.5-8.5s8.5 3.8 8.5 8.5v.1zM76.4 60.1c0 3.3-2.7 6-6 6s-6-2.7-6-6 2.7-6 6-6c3.4 0 6 2.7 6 6zM52.1 94.7h40M52.1 104.2h40M52.1 113.6h20"
            className="st4"
        />
        <path
            d="M43.1 118.5c-7.9 0-14.4-6.4-14.4-14.4 0-7.9 6.4-14.4 14.4-14.4s14.4 6.4 14.4 14.4-6.5 14.4-14.4 14.4z"
            style={{
                fill: "#43cc6b",
            }}
        />
        <path
            d="m39.6 104.7 3.5 2.7 4.4-5.3"
            style={{
                fill: "none",
                stroke: "#fff",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
)
export default SvgComponent
