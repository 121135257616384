import React, {useEffect, useState} from 'react';
import {Input, InputNumber} from "antd";

const SimpleInput = (props) => {
    const { initValue, value, onChangeCallback, isPassword, isNumericOnly,  ...rest } = props;
    const [val, setVal] = useState('');

    useEffect(() => {
        //this is a workaround for when we need a flag to update the default value (no dependency is not good and [value] dependency is keeping the bug)
        setVal(initValue)
    }, [initValue]);

    useEffect(() => {
        setVal(value)
    }, []);

    const onTextChanged = (value) => {
        const val = isNumericOnly ? value : value.target.value;
        setVal(val)
        if(onChangeCallback) onChangeCallback(val)
    }

    if(isPassword) return <Input.Password value={val} onChange={onTextChanged} {...rest} />

    if(isNumericOnly) return <InputNumber value={val} size="large" className={'simple-input-number'} onChange={onTextChanged} {...rest} />

    return (
        <Input value={val} onChange={onTextChanged} {...rest}/>
    );
};

export default SimpleInput;