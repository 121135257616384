import { combineReducers, configureStore } from '@reduxjs/toolkit'
import siteReducer from './siteSlice'
import authReducer from './authSlice'
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from "redux-persist";
import modalManagerSlice from "./modalManagerSlice";
import userSlice from "./userSlice";
import stepperSlice from "./stepperSlice";
import {createLogger} from "redux-logger";

const logger = createLogger();

const reducer = combineReducers({
    site: siteReducer,
    auth: authReducer,
    modalManager: modalManagerSlice,
    user: userSlice,
    stepper: stepperSlice
})

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'site'],
    version: 10,
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    // .concat(process.env.NODE_ENV !== 'production' ? logger : null),
    devTools: process.env.NODE_ENV !== 'production',
    reducer: persistedReducer
})

export const persistor = persistStore(store);
