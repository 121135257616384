import React, {useEffect, useRef, useState} from 'react';
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {Calendar, DatePicker, Drawer} from "antd";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import locale from 'antd/es/date-picker/locale/he_IL';
import 'dayjs/locale/he'
import {useSelector} from "react-redux";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const CustomDatePicker = (props) => {
    const { onDateSelect, defaultDate, anchor, ...rest } = props;
    const language = useSelector(state => state.site.language)
    const [openMobilePopup, setOpenMobilePopup] = useState(false);
    const [openDesktop, setOpenDesktop] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [initSelection, setInitSelection] = useState(null);
    const [mobileWorkaroundDate, setMobileWorkaroundDate] = useState(dayjs()); // this is because calendar (in mobile) triggers date change on month/year select (which closes the popup)
    const {isMobile} = useScreenSize();

    // both useeffects are a workaround for "default value": store the default value in initSelection --> set selection to same value --> use initSelection as (default) value inside Datepicker component --> reset initSelection on selection change
    useEffect(() => {
        if(defaultDate) {
            setSelectedDate(dayjs(defaultDate, DB_DATE_FORMAT))
            setMobileWorkaroundDate(dayjs(defaultDate, DB_DATE_FORMAT))
        }
    }, [defaultDate]);

    useEffect(() => {
        dateSelected()
        setInitSelection(null)
    }, [selectedDate]);

    const dateSelected = () => {
        if(onDateSelect) {
            const newDate = selectedDate ? selectedDate.format(DB_DATE_FORMAT) : null
            if(newDate !== selectedDate) {
                onDateSelect(newDate)
                setOpenMobilePopup(false)
            }
        }
    }

    return (
        <>
            {anchor ? <div onClick={() => setOpenMobilePopup(true)}>{anchor}</div>
            :
            <DatePicker className={'custom-datepicker'}
                        format={'DD MMMM YYYY'}
                        showToday={false}
                        open={openDesktop}
                        value={initSelection ?? selectedDate}
                        onOpenChange={(open) => isMobile ? setOpenMobilePopup(open) : setOpenDesktop(open)}
                        onChange={setSelectedDate}
                        suffixIcon={<FontAwesomeIcon icon={light('calendar')} color={Colors.siteBlack}/>}
                        placeholder={''}
                        locale={language?.code === 'he' ? locale : null}
                        {...rest}
            />}
            <Drawer
                className={'custom-calendar-mobile-drawer'}
                placement={'bottom'}
                closable={false}
                onClose={() => setOpenMobilePopup(false)}
                open={openMobilePopup}
                style={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}
                contentWrapperStyle={{height: 'auto'}}>
                <Calendar className={'custom-calendar-mobile'}
                          fullscreen={false}
                          onSelect={(date, {source}) => source === 'date' ? setSelectedDate(date) : setMobileWorkaroundDate(date)}
                          value={mobileWorkaroundDate}
                          locale={language?.code === 'he' ? locale : null}
                          {...rest}
                />
            </Drawer>
        </>
    );
};

export default CustomDatePicker;