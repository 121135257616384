import React from 'react';
import {BoldCollapseText, FlexColumnSection, FlexRowSection, SmallText} from "../styles/globalStyles";
import styled from "styled-components";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {t} from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const WhatsNextBox = (props) => {
    const { text } = props;

    return (
        <FlexColumnExtended gap={'5px'}>
            <FlexRowSection gap={'8px'}>
                <FontAwesomeIcon icon={solid('circle-info')} color={Colors.siteBlack} size={'lg'}/>
                <BoldCollapseText>{t('whats-next')}</BoldCollapseText>
            </FlexRowSection>
            <SmallText>{text}</SmallText>
        </FlexColumnExtended>
    );
};

export default WhatsNextBox;

const FlexColumnExtended = styled(FlexColumnSection)`
    padding: 20px;
    background: ${Colors.white};
    width: 250px;
    flex: none;
    border-radius: 5px;
`;