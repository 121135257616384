import React from 'react';
import styled from "styled-components";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import SimpleInput from "./SimpleInput";
import {ErrorText, FlexRowSection, GreyText} from "../../styles/globalStyles";
import {t} from "i18next";

const FormInput = (props) => {
    const { width, label, error, children, flex, showOptional, ...rest } = props;

    return (
        <Wrapper width={width} flex={flex}>
            {label &&
            <FlexRowSection justify={'space-between'} flex={'none'}>
                <Label>{label}</Label>
                {showOptional && <GreyText>{t('optional')}</GreyText>}
            </FlexRowSection>
            }
            {children || <SimpleInput status={error ? 'error' : null} {...rest}/>}
            {error && <ErrorText>{error}</ErrorText>}
        </Wrapper>
    );
};

export default FormInput;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: ${({width}) => width ?? 'auto'};
    flex: ${({flex}) => flex ?? 'none'};
`;

const Label = styled.div`
    font-size: 14px;
    font-weight: 500;
`;