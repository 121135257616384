import React from 'react';
import useScreenSize from "../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {BoldCollapseText, FlexColumnSection, FlexRowSection, SmallText} from "../../styles/globalStyles";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {t} from "i18next";
import GooglePlay from "../../svg/GooglePlay";
import AppStore from "../../svg/AppStore";
import {useSelector} from "react-redux";
import {QRCode, Space} from "antd";

const DownloadApp = (props) => {
    const { } = props;
    const appLink = useSelector(state => state.site.appLink)
    const {isMobile} = useScreenSize()

    return (
        <>
            {
                isMobile ?
                    <FlexColumnPadding gap={'20px'} background={Colors.white} flex={'none'}>
                        <FlexColumnSection gap={'10px'} flex={'none'}>
                            <BoldCollapseText>{t('download-app-header')}</BoldCollapseText>
                            <SmallText>{t('download-app-subheader')}</SmallText>
                        </FlexColumnSection>
                        <FlexRowSection gap={'10px'}>
                            <div onClick={() => window.open(appLink)}><GooglePlay height={38}/></div>
                            <div onClick={() => window.open(appLink)}><AppStore height={38}/></div>
                        </FlexRowSection>
                    </FlexColumnPadding>
                    :
                    <DesktopWrapper align={'start'}>
                        <QRCode value={appLink} size={100} bordered={false}/>
                        <FlexColumnSection padding={'15px'}>
                            <BoldCollapseText>{t('download-app-header')}</BoldCollapseText>
                            <SmallText>{t('download-app-subheader')}</SmallText>
                        </FlexColumnSection>
                    </DesktopWrapper>
            }
        </>
    );
};

export default DownloadApp;

const FlexColumnPadding = styled(FlexColumnSection)`
    background-color: ${({background}) => background ?? 'none'};
    border-radius: 5px;
    padding: 20px;
`;

const DesktopWrapper = styled(FlexRowSection)`
    background-color: ${Colors.white};
    border-radius: 5px;
    border: 1px solid ${Colors.siteScheduleBorder};
    width: 330px;
    padding: 5px;
`;
